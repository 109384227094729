import i18n from "i18next";

/**
 * @name isWebview
 * @description A function that determines whether the current window is a webview.
 * We are in a webview if the user-agent contains the marketplace name.
 */
const isWebview = (w: typeof window | null | undefined = window): boolean => {
  if (w && w.navigator) {
    const ua = w.navigator.userAgent.toLowerCase();
    const userAgentContainsMarketplace = ua.includes(
      i18n.t("MARKETPLACE_NAME").toLowerCase(),
    );
    return userAgentContainsMarketplace;
  }

  return false;
};

export default isWebview;
