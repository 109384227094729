import type { SavedSearch } from "storefront/SavedSearch";
import { put } from "storefront/GrailedAPI/request";

type Response = {
  data: SavedSearch;
};

/**
 * @name GrailedAPI.v1.SavedSearches.rename
 * @param {string} uuid - the UUID of the SavedSearch being renamed
 * @param {string} name - the new name for the SavedSearch
 * @return {Promise<Response>} a promise that resolves with a response containing the updated search
 */
const rename: (arg0: string, arg1: string) => Promise<Response> = (
  uuid,
  name,
) =>
  put(`/api/saved_searches/${uuid}/rename`, {
    name,
  });

export default rename;
