import { init, ErrorBoundary } from "@sentry/react";

const sentryInit = () => {
  init({
    debug: false,
    dsn: process.env.WEB_SENTRY_DSN || "",
    environment:
      process.env.HEROKU_APP_NAME ||
      process.env.SENTRY_ENVIRONMENT_NAME ||
      "development",
    maxBreadcrumbs: 10,
  });
};

export { ErrorBoundary, sentryInit };
