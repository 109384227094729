import type { Reducer } from "redux";

/**
 * @name id
 * @description This is a reducer that doesn't handle any actions. It just returns whatever it's
 * given, no matter what.
 */
const id: Reducer<any, any> = (state = {}, _action) => state;

export default id;
