import type { Reducer } from "redux";
import { set } from "lodash/fp";
import type { Action } from "storefront/Action";
import type { Listings } from "storefront/Listings";
import {
  UNFAVORITE_LISTING_REQUEST,
  FAVORITE_LISTING_REQUEST,
  UNFAVORITE_LISTING_ERROR,
  FAVORITE_LISTING_ERROR,
} from "storefront/actions/constants";
import { updateListing } from "storefront/Listings";

const initialState: Listings = {
  hits: {
    grailed: 0,
    hype: 0,
    basics: 0,
  },
  listings: [],
  categories: {},
  isLoading: false,
  noMoreListings: false,
  initialPage: "",
  totalHits: 0,
  initialTotalHits: null,
  feedRequester: null,
  listingsNextPage: null,
};

const listings: Reducer<Listings, Action> = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case FAVORITE_LISTING_REQUEST:
    case UNFAVORITE_LISTING_ERROR:
      return {
        ...state,
        listings: updateListing(set("followed", true))(payload.listingId)(
          state,
        ),
      };

    case UNFAVORITE_LISTING_REQUEST:
    case FAVORITE_LISTING_ERROR:
      return {
        ...state,
        listings: updateListing(set("followed", false))(payload.listingId)(
          state,
        ),
      };

    default:
      return state;
  }
};

export default listings;
