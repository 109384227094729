import { camelize } from "camelscore";
import type { Articles } from "../types/State/Articles";

const articles = ({
  ARTICLE,
  FRANCHISES,
  HOMEPAGE_PREVIEW_ARTICLE_IDS,
}: typeof window): Articles => ({
  isLoadingArticles: false,
  isViewingList: !ARTICLE,
  isEditing: !!ARTICLE,
  isPreviewing: false,
  currentArticle: ARTICLE ? camelize(ARTICLE) : null,
  loadingComments: false,
  articles: {
    data: [],
    metadata: null,
  },
  franchises: FRANCHISES || [],
  comments: [],
  commentText: "",
  homepagePreviewArticleIds: HOMEPAGE_PREVIEW_ARTICLE_IDS || [],
  error: null,
  hasMoreArticles: false,
});

export default articles;
