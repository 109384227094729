import {
  FETCH_TRUST_SCORES_REQUEST,
  FETCH_TRUST_SCORES_ERROR,
  FETCH_TRUST_SCORES_SUCCESS,
  CREATE_TRUST_SCORE_REQUEST,
  CREATE_TRUST_SCORE_ERROR,
  CREATE_TRUST_SCORE_SUCCESS,
} from "../constants/action_types";
import GrailedAPI from "../lib/grailed_api";
import type { TrustScore } from "../types/TrustScore";

export function createTrustScoreSuccess(
  trustScore: TrustScore,
): Record<string, any> {
  return {
    type: CREATE_TRUST_SCORE_SUCCESS,
    payload: {
      trustScore,
    },
  };
}
export function createTrustScoreError(error: Error): Record<string, any> {
  return {
    type: CREATE_TRUST_SCORE_ERROR,
    payload: {
      error,
    },
  };
}
export function createTrustScoreRequest(): Record<string, any> {
  return {
    type: CREATE_TRUST_SCORE_REQUEST,
    payload: {},
  };
}
export function fetchTrustScoresRequest(): Record<string, any> {
  return {
    type: FETCH_TRUST_SCORES_REQUEST,
    payload: {},
  };
}
export function fetchTrustScoresSuccess(
  trustScores: TrustScore[],
  metadata: Record<string, any>,
): Record<string, any> {
  return {
    type: FETCH_TRUST_SCORES_SUCCESS,
    payload: {
      data: trustScores,
      metadata,
    },
  };
}
export function fetchTrustScoresError(error: Error): Record<string, any> {
  return {
    type: FETCH_TRUST_SCORES_ERROR,
    payload: {
      error,
    },
  };
}
export function fetchTrustScores(userId: number) {
  return (dispatch: (...args: Array<any>) => any) => {
    dispatch(fetchTrustScoresRequest());
    GrailedAPI.trustScores
      .all(userId)
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      .then((response) => {
        dispatch(fetchTrustScoresSuccess(response.data, response.metadata));
      })
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
      .catch((error) => dispatch(fetchTrustScoresError(error)));
  };
}
export function fetchMoreTrustScores(url: string) {
  return (dispatch: (...args: Array<any>) => any) => {
    dispatch(fetchTrustScoresRequest());
    GrailedAPI.trustScores
      .fetchPage(url)
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      .then((response) =>
        dispatch(fetchTrustScoresSuccess(response.data, response.metadata)),
      )
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
      .catch((error) => dispatch(fetchTrustScoresError(error)));
  };
}
