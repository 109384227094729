import type { Id } from "storefront/lib/Id";
import type { PageType } from "storefront/Analytics/Event";

export type UserFollowProperties = {
  isFollow: boolean;
  followedUserId: Id;
  pageType: PageType;
  pageTypeName?: string;
  pageTypeIdentifier?: Id;
  userId: Id;
};
export type UserFollowEvent = {
  object: "user";
  action: "followed" | "unfollowed";
  properties: {
    followedUserId: Id;
    pageType: PageType;
    pageTypeName: string | null | undefined;
    pageTypeIdentifier: Id | null | undefined;
    userId: Id;
  };
};

/**
 * @description Creates an event that can be given to `track` to record User Follow and User Unfollow
 */
const toggleUserFollow = ({
  isFollow,
  followedUserId,
  pageType,
  pageTypeName,
  pageTypeIdentifier,
  userId,
}: UserFollowProperties): UserFollowEvent => ({
  object: "user",
  action: isFollow ? "followed" : "unfollowed",
  properties: {
    followedUserId,
    pageType,
    pageTypeName,
    pageTypeIdentifier,
    userId,
  },
});

export default toggleUserFollow;
