import { propOr } from "lodash/fp";
import { fromWindow } from "storefront/Config/PublicConfig";
import { emptyGroups } from "storefront/InternalTools/Groups";
import type { InternalTools } from "storefront/InternalTools";
import { empty as emptyCapsules } from "storefront/Capsules";

const internalTools = (w: typeof window): InternalTools => ({
  bannedIPs: w.bannedIPs || [],
  capsules: emptyCapsules,
  focusedCategorySavedSearch: null,
  focusedCategory: null,
  merchant: {
    sellerIds: [],
    buyerIds: [],
  },
  trustScore: {
    scoreOptions: propOr([], "trustOptions.scores", fromWindow(w)),
    reasonOptions: propOr([], "trustOptions.reasons", fromWindow(w)),
  },
  items: {
    content: {},
    trustScores: {
      isLoading: false,
      error: false,
      data: [],
      metadata: {},
      hasMore: false,
    },
  },
  categorySearches: [],
  error: null,
  groups: emptyGroups,
  isLoading: false,
  isLoadingRefund: false,
  refundError: null,
  relatedUsers: [],
  flags: {
    metadata: {},
    data: [],
  },
  hasMoreFlags: false,
  isLoadingFlags: false,
});

export default internalTools;
