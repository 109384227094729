import {
  BAN_IP,
  UNBAN_IP,
  INTERNAL_TOOLS_CONTENT_REQUEST,
  INTERNAL_TOOLS_CONTENT_REQUEST_SUCCESS,
  INTERNAL_TOOLS_GROUPS_REQUEST,
  INTERNAL_TOOLS_GROUPS_REQUEST_SUCCESS,
  INTERNAL_TOOLS_FETCH_RELATED_USERS_SUCCESS,
  INTERNAL_TOOLS_FETCH_RELATED_USERS_REQUEST,
  INTERNAL_TOOLS_FETCH_RELATED_USERS_ERROR,
} from "../constants/action_types";
import GrailedAPI from "../lib/grailed_api";
import type { GroupTree } from "../types/GroupTree";
import type { ListingCategorySlug } from "../types/ListingCategorySlug";
import getRelatedUsers from "../lib/GrailedAPI/v1/Internal/Users/getRelatedUsers";
import { GrailedAPILightListing } from "../../storefront/Listing/GrailedAPILightListing";
import { ListingDraft } from "../../storefront/ListingDraft";
import { Pagination } from "../../storefront/Pagination";

export function banIP(ip: string) {
  return {
    type: BAN_IP,
    payload: {
      ip,
    },
  };
}

export function unbanIP(ip: string) {
  return {
    type: UNBAN_IP,
    payload: {
      ip,
    },
  };
}

export const internalToolsContentRequest = (category: string) => ({
  type: INTERNAL_TOOLS_CONTENT_REQUEST,
  payload: {
    category,
  },
});

type UserContent = {
  data: Array<GrailedAPILightListing> | Array<ListingDraft>;
  metadata: {
    count: number;
    pagination: Pagination;
  };
};

export const internalToolsContentRequestSuccess = (
  category: string,
  { data: content, metadata }: UserContent,
) => ({
  type: INTERNAL_TOOLS_CONTENT_REQUEST_SUCCESS,
  payload: {
    category,
    content,
    metadata,
  },
});

export const fetchInternalUserContent =
  (userId: number, category: ListingCategorySlug) =>
  (dispatch: (...args: Array<any>) => any) => {
    dispatch(internalToolsContentRequest(category));
    GrailedAPI.internalTools
      .userContent(userId, category)
      .then((response: UserContent) => {
        dispatch(internalToolsContentRequestSuccess(category, response));
      });
  };

export const fetchInternalUserListingsPage =
  (path: string, category: ListingCategorySlug) =>
  (dispatch: (...args: Array<any>) => any) => {
    dispatch(internalToolsContentRequest(category));
    GrailedAPI.internalTools
      .fetchUserListingsPage(path)
      .then((response: UserContent) =>
        dispatch(internalToolsContentRequestSuccess(category, response)),
      );
  };

export const fetchInternalAllUserContent =
  (userId: number, categories: Array<ListingCategorySlug>) =>
  (dispatch: (...args: Array<any>) => any) => {
    categories.forEach((category) =>
      dispatch(fetchInternalUserContent(userId, category)),
    );
  };

export const internalToolsGroupsRequest = () => ({
  type: INTERNAL_TOOLS_GROUPS_REQUEST,
  payload: {},
});

export const internalToolsGroupsRequestSuccess = (groups: GroupTree) => ({
  type: INTERNAL_TOOLS_GROUPS_REQUEST_SUCCESS,
  payload: {
    groups,
  },
});

export const fetchInternalGroups =
  () => (dispatch: (...args: Array<any>) => any) => {
    dispatch(internalToolsGroupsRequest());
    GrailedAPI.groups.all().then((response: { data: GroupTree }) => {
      dispatch(internalToolsGroupsRequestSuccess(response.data));
    });
  };

export const internalToolsFetchRelatedUsersRequest = () => ({
  type: INTERNAL_TOOLS_FETCH_RELATED_USERS_REQUEST,
  payload: {},
});

export const internalToolsFetchRelatedUsersSuccess = (
  data: Record<string, any>,
) => ({
  type: INTERNAL_TOOLS_FETCH_RELATED_USERS_SUCCESS,
  payload: {
    data,
  },
});

export const internalToolsFetchRelatedUsersError = (error: Error) => ({
  type: INTERNAL_TOOLS_FETCH_RELATED_USERS_ERROR,
  payload: {
    error,
  },
});

export const fetchRelatedUsers =
  (userId: number) => (dispatch: (...args: Array<any>) => any) => {
    dispatch(internalToolsFetchRelatedUsersRequest());
    getRelatedUsers(userId)
      .then((response) =>
        dispatch(internalToolsFetchRelatedUsersSuccess(response.data)),
      )
      .catch((response) =>
        dispatch(internalToolsFetchRelatedUsersError(response.body)),
      );
  };
