import type { Reducer } from "redux";
import { set, update, remove, compose, findIndex } from "lodash/fp";
import type { Capsule } from "storefront/Capsule";
import type { Action } from "storefront/Action";
import {
  CAPSULES_CMS_MOUNTED,
  CAPSULES_GET_ALL_CAPSULES_RESPONDED,
  CAPSULES_GET_ALL_CAPSULES_ERRORED,
  CAPSULES_CREATE_RESPONDED,
  CAPSULES_CMS_LIST_WAYPOINT_ENTERED,
  CAPSULES_CMS_LIST_CLICKED,
} from "storefront/actions/constants";
import { empty as initialState } from "storefront/Capsules";
import type { Capsules } from "storefront/Capsules";
import {
  CAPSULES_CMS_EDIT_DELETED,
  CAPSULES_CMS_EDIT_UPDATED,
  CAPSULES_CMS_EDIT_PUBLISHED,
  CAPSULES_CMS_EDIT_UNPUBLISHED,
} from "../constants/action_types";

const replace: (arg0: Capsule) => (arg0: Array<Capsule>) => Array<Capsule> =
  (capsule) => (c) => {
    const index = findIndex(["id", capsule.id], c);
    return [...c.slice(0, index), capsule, ...c.slice(index + 1)];
  };

const capsules: Reducer<Capsules, Action> = (state = initialState, action) => {
  switch (action.type) {
    case CAPSULES_CMS_MOUNTED:
    case CAPSULES_CMS_LIST_WAYPOINT_ENTERED:
      return set("capsules.isLoading", true, state);

    case CAPSULES_GET_ALL_CAPSULES_RESPONDED:
      return compose([
        update("capsules.data", (c) => [...c, ...action.payload.data]),
        set("capsules.isLoading", false),
        set("capsules.nextPage", action.payload.metadata.pagination.nextPage),
        set("capsules.hasMoreCapsules", action.payload.data.length > 0),
      ])(state);

    case CAPSULES_GET_ALL_CAPSULES_ERRORED:
      return set("capsules.isLoading", false, state);

    case CAPSULES_CREATE_RESPONDED:
      return update("capsules.data", (c) => [action.payload, ...c], state);

    case CAPSULES_CMS_LIST_CLICKED:
      return set("currentCapsule", action.payload, state);

    case CAPSULES_CMS_EDIT_DELETED:
      return compose([
        update(
          "capsules.data",
          // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          remove((c) => c.id === action.payload.id),
        ),
        set("currentCapsule", null),
      ])(state);

    case CAPSULES_CMS_EDIT_UPDATED:
    case CAPSULES_CMS_EDIT_PUBLISHED:
    case CAPSULES_CMS_EDIT_UNPUBLISHED:
      return compose([
        update("capsules.data", replace(action.payload)),
        set("currentCapsule", action.payload),
      ])(state);

    default:
      return state;
  }
};

export default capsules;
