import React from "react";
import { createRoot } from "react-dom/client";
import type { Store } from "redux";
import type { ComponentType } from "react";
import { bindActionCreators, AnyAction } from "redux";
import { Provider } from "react-redux";
import { Provider as AuthenticationProvider } from "storefront/hooks/useAuthentication";
import { Provider as AnalyticsProvider } from "storefront/hooks/useAnalytics";
import * as fittingRoomActions from "storefront/actions/fittingRoomActions";
import * as filteringActions from "storefront/actions/filteringActions";
import InitialDataProvider from "storefront/InitialDataProvider";
import * as conversationActions from "../actions/conversations_actions";
import * as sessionActions from "../actions/session_actions";
import * as articlesActions from "../actions/articles_actions";
import * as internalToolsActions from "../actions/internal_tools_actions";
import * as listingShowActions from "../actions/listing_show_actions";
import * as trustScoreActions from "../actions/trust_score_actions";
import * as merchantActions from "../actions/merchant_actions";

export const bindActions = (store: Store<Record<string, any>, AnyAction>) =>
  bindActionCreators(
    {
      ...conversationActions,
      ...fittingRoomActions,
      ...sessionActions,
      ...filteringActions,
      ...articlesActions,
      ...internalToolsActions,
      ...listingShowActions,
      ...trustScoreActions,
      ...merchantActions,
    },
    store.dispatch,
  );

const createApp = (
  rootElement: HTMLElement,
  Yield: ComponentType<any>,
  store: Store<Record<string, any>, AnyAction>,
  props?: Record<string, any>,
): void => {
  const actions = bindActions(store);
  window.actions = actions;
  const root = createRoot(rootElement);
  root.render(
    <InitialDataProvider>
      <Provider store={store}>
        <AuthenticationProvider>
          <AnalyticsProvider>
            <Yield actions={actions} {...props} />
          </AnalyticsProvider>
        </AuthenticationProvider>
      </Provider>
    </InitialDataProvider>,
  );
};

export default createApp;
