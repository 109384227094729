import {
  FETCH_MERCHANT_REQUEST,
  FETCH_MERCHANT_SUCCESS,
  FETCH_MERCHANT_ERROR,
} from "../constants/action_types";
import GrailedAPI from "../lib/grailed_api";
import type { Merchant } from "../types/Merchant";

export function fetchMerchantRequest(): Record<string, any> {
  return {
    type: FETCH_MERCHANT_REQUEST,
    payload: {},
  };
}
export function fetchMerchantSuccess(merchant: Merchant): Record<string, any> {
  return {
    type: FETCH_MERCHANT_SUCCESS,
    payload: {
      data: merchant,
    },
  };
}
export function fetchMerchantError(error: Error): Record<string, any> {
  return {
    type: FETCH_MERCHANT_ERROR,
    payload: {
      error,
    },
  };
}
export function fetchMerchant(userId: number) {
  return (dispatch: (...args: Array<any>) => any) => {
    dispatch(fetchMerchantRequest());
    GrailedAPI.internalTools
      .merchantSellerBuyerIds(userId)
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      .then((response) => {
        dispatch(fetchMerchantSuccess(response.data));
      })
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
      .catch((error) => dispatch(fetchMerchantError(error)));
  };
}
