import { del } from "storefront/GrailedAPI/request";

type Response = Record<string, never>;

/**
 * @name GrailedAPI.v1.SavedSearches.unsubscribe
 * @param {string} uuid - uuid of SavedSearch being unsubscribed from the user
 * @return {Promise<Response>}
 */
const unsubscribe: (arg0: string) => Promise<Response> = (uuid) =>
  del(`/api/saved_searches/${uuid}/unsubscribe`);

export default unsubscribe;
