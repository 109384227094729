import uniqBy from "lodash/fp/uniqBy";
import getOr from "lodash/fp/getOr";
import { FETCH_UNREAD_COUNTS_SUCCESS } from "storefront/_app/FetchUnreadCountsSuccessAction";
import { ACTIVITY_ADDED } from "storefront/ReduxActions/Conversation/ActivityAdded";
import type { ActivityAdded as ActivityAddedAction } from "storefront/ReduxActions/Conversation/ActivityAdded";
import { OFFER_MODAL_CLOSED } from "storefront/ReduxActions/Conversation/OfferModalClosed";
import type { OfferModalClosed as OfferModalClosedAction } from "storefront/ReduxActions/Conversation/OfferModalClosed";
import { REPLY_MODAL_CLOSED } from "storefront/ReduxActions/Conversation/ReplyModalClosed";
import type { ReplyModalClosed as ReplyModalClosedAction } from "storefront/ReduxActions/Conversation/ReplyModalClosed";
import { CREATE_SHIPMENT_SUCCESS } from "storefront/ReduxActions/Shipment/CreateShipment";
import { EDIT_SHIPMENT_SUCCESS } from "storefront/ReduxActions/Shipment/EditShipment";
import { GRANT_EXTENSION_SUCCESS } from "storefront/ReduxActions/Conversation/GrantExtensionSuccess";
import type { WithholdingInfo } from "storefront/Listing";
import {
  FETCH_CONVERSATIONS_REQUEST,
  FETCH_CONVERSATIONS_SUCCESS,
  FETCH_CONVERSATIONS_ERROR,
  FETCH_CONVERSATION_REQUEST,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATION_ERROR,
  FOCUS_CONVERSATION,
  UNFOCUS_CONVERSATION,
  ACCEPT_OFFER_REQUEST,
  ACCEPT_OFFER_SUCCESS,
  ACCEPT_OFFER_ERROR,
  CONVERSATION_EXPAND_ACTIVITY_LOG,
  CONVERSATION_FINISHED_SLIDING_DOWN_ACTIVITY_LOG,
  MARK_CONVERSATION_AS_READ_SUCCESS,
  SET_CONVERSATION_CONTEXT,
  EXPAND_CONVERSATION_SETTINGS,
  HIDE_CONVERSATION_SETTINGS,
  BLOCK_CONVERSATION_USER_SUCCESS,
  BLOCK_CONVERSATION_USER_ERROR,
  UNBLOCK_CONVERSATION_USER_SUCCESS,
  UNBLOCK_CONVERSATION_USER_ERROR,
  ARCHIVE_CONVERSATION_SUCCESS,
  UNARCHIVE_CONVERSATION_SUCCESS,
  OPEN_OFFER_MODAL,
  OPEN_REPLY_MODAL,
} from "../constants/action_types";
import { CONVERSATIONS_CONTEXT_LOCAL_STORAGE_KEY } from "../constants/constants";
import type { UnblockAction } from "../components/conversations/ConversationSettings/unblockActionCreator";
import type { BlockAction } from "../components/conversations/ConversationSettings/blockActionCreator";
import type { ArchiveAction } from "../components/conversations/ConversationSettings/archiveActionCreator";
import type { UnarchiveAction } from "../components/conversations/ConversationSettings/unarchiveActionCreator";

function contains<T>(list: Array<T>, item: T): boolean {
  return list.indexOf(item) >= 0;
}

function addItem<T>(list: Array<T>, item: T): Array<T> {
  if (contains(list, item)) {
    return list;
  }

  return [...list, item];
}

function removeItem<T>(list: Array<T>, item: T): Array<T> {
  return list.filter((element) => element !== item);
}

type Action =
  | UnblockAction
  | BlockAction
  | ArchiveAction
  | UnarchiveAction
  | ActivityAddedAction
  | OfferModalClosedAction
  | ReplyModalClosedAction
  | Record<string, any>;
export default function conversationsReducer(
  state: Record<string, any> = {},
  action: Action,
): Record<string, any> {
  switch (action.type) {
    case FETCH_UNREAD_COUNTS_SUCCESS:
      return {
        ...state,
        buying: {
          ...state.buying,
          unreadCount: getOr(0, "payload.buying.unreadCount", action),
        },
        selling: {
          ...state.selling,
          unreadCount: getOr(0, "payload.selling.unreadCount", action),
        },
      };

    case FETCH_CONVERSATION_REQUEST:
      return {
        ...state,
        individualLoadingConversations: addItem(
          state.individualLoadingConversations,
          action.payload.conversationId,
        ),
      };

    case FETCH_CONVERSATIONS_REQUEST:
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          loadingConversations: true,
        },
      };

    case FETCH_CONVERSATION_ERROR:
      return {
        ...state,
        individualLoadingConversations: removeItem(
          state.individualLoadingConversations,
          action.payload.conversationId,
        ),
        [state.context]: {
          ...state[state.context],
          error: action.payload.error,
        },
      };

    case FETCH_CONVERSATIONS_ERROR:
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          loadingConversations: false,
          error: action.payload.error,
        },
      };

    case FETCH_CONVERSATION_SUCCESS: {
      const convos = state[state.context].conversations;
      return {
        ...state,
        individualLoadingConversations: removeItem(
          state.individualLoadingConversations,
          action.payload.data.id,
        ),
        [state.context]: {
          ...state[state.context],
          activeConversationIds: addItem(
            state[state.context].activeConversationIds,
            action.payload.data.id,
          ),
          conversations: tweak(
            convos,
            (c) => c.id === action.payload.data.id,
            replaceCompletelyWith(action.payload.data),
          ),
          unreadCount: getOr(0, "payload.metadata.unreadCount", action),
        },
      };
    }

    case FETCH_CONVERSATIONS_SUCCESS:
      const { response, context, page } = action.payload;
      const newConvos = response.data;
      const allConvos = [...state[context].conversations, ...newConvos];
      return {
        ...state,
        [context]: {
          ...state[context],
          loadingConversations: false,
          noMoreConversations: !newConvos.length,
          conversations: uniqBy("id", allConvos),
          unreadCount: getOr(0, "metadata.unreadCount", response),
          nextPage: page + 1,
          error: null,
        },
      };

    case FOCUS_CONVERSATION: {
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          activeConversationIds: addItem(
            state[state.context].activeConversationIds,
            action.payload.activeConversationId,
          ),
        },
      };
    }

    case UNFOCUS_CONVERSATION:
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          activeConversationIds: removeItem(
            state[state.context].activeConversationIds,
            action.payload.unfocusedConversationId,
          ),
          expandedConversationIds: removeItem(
            state[state.context].expandedConversationIds,
            action.payload.unfocusedConversationId,
          ),
          slidDownConversationIds: removeItem(
            state[state.context].slidDownConversationIds,
            action.payload.unfocusedConversationId,
          ),
        },
      };

    case MARK_CONVERSATION_AS_READ_SUCCESS: {
      const { conversations } = state[state.context];
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          conversations: tweak(
            conversations,
            (c) => c.id === action.payload.id,
            markAsRead,
          ),
          unreadCount: Math.max(0, state[state.context].unreadCount - 1),
        },
      };
    }

    case ACCEPT_OFFER_REQUEST:
      return {
        ...state,
        [state.context]: { ...state[state.context], ajaxRequestLoading: true },
      };

    case ACCEPT_OFFER_SUCCESS:
      return {
        ...state,
        [state.context]: { ...state[state.context], ajaxRequestLoading: false },
      };

    case ACCEPT_OFFER_ERROR:
      return {
        ...state,
        [state.context]: { ...state[state.context], ajaxRequestLoading: false },
      };

    case ACTIVITY_ADDED: {
      const convos = state[state.context].conversations;
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          conversations: tweak(
            convos,
            (c) => c.id === action.payload.conversation.id,
            replaceCompletelyWith(action.payload.conversation),
          ),
        },
      };
    }

    case CONVERSATION_EXPAND_ACTIVITY_LOG:
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          expandedConversationIds: addItem(
            state[state.context].expandedConversationIds,
            action.payload.conversationId,
          ),
        },
      };

    case CONVERSATION_FINISHED_SLIDING_DOWN_ACTIVITY_LOG:
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          slidDownConversationIds: [
            action.payload.conversationId,
            ...state[state.context].slidDownConversationIds,
          ],
        },
      };

    case SET_CONVERSATION_CONTEXT:
      try {
        // Safari Incognito will throw when accessing localStorage.
        localStorage.setItem(
          CONVERSATIONS_CONTEXT_LOCAL_STORAGE_KEY,
          action.payload.context,
        );
      } catch (e) {}

      return { ...state, context: action.payload.context };

    case EXPAND_CONVERSATION_SETTINGS:
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          expandedSettings: action.payload.conversationId,
        },
      };

    case HIDE_CONVERSATION_SETTINGS:
      return {
        ...state,
        [state.context]: { ...state[state.context], expandedSettings: null },
      };

    case BLOCK_CONVERSATION_USER_SUCCESS: {
      const convos = state[state.context].conversations;
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          conversations: tweak(
            convos,
            (c) => c.id === action.payload.conversation.id,
            blockInterlocutor,
          ),
        },
      };
    }

    case UNBLOCK_CONVERSATION_USER_SUCCESS: {
      const convos = state[state.context].conversations;
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          conversations: tweak(
            convos,
            (c) => c.id === action.payload.conversation.id,
            unblockInterlocutor,
          ),
        },
      };
    }

    case BLOCK_CONVERSATION_USER_ERROR:
    case UNBLOCK_CONVERSATION_USER_ERROR:
      return { ...state, error: action.payload.error };

    case ARCHIVE_CONVERSATION_SUCCESS:
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          conversations: state[state.context].conversations.filter(
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'c' implicitly has an 'any' type.
            (c) => c.id !== action.payload.conversation.id,
          ),
        },
      };

    case UNARCHIVE_CONVERSATION_SUCCESS:
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          conversations: state[state.context].conversations.filter(
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'c' implicitly has an 'any' type.
            (c) => c.id !== action.payload.conversation.id,
          ),
        },
      };

    case OPEN_OFFER_MODAL:
      return {
        ...state,
        offerModalIsOpen: true,
        offerModalConversationId: action.payload.conversationId,
      };

    case OFFER_MODAL_CLOSED:
      return {
        ...state,
        offerModalIsOpen: false,
        offerModalConversationId: null,
      };

    case OPEN_REPLY_MODAL:
      return {
        ...state,
        replyModalIsOpen: true,
        replyModalConversationId: action.payload.conversationId,
      };

    case REPLY_MODAL_CLOSED:
      return {
        ...state,
        replyModalIsOpen: false,
        replyModalConversationId: null,
      };

    case EDIT_SHIPMENT_SUCCESS: {
      const convos = state[state.context].conversations;
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          conversations: tweak(
            convos,
            (c) => c.id === action.payload.conversationId,
            replaceShipmentWith(
              action.payload.oldShipmentId,
              action.payload.shipment,
            ),
          ),
        },
      };
    }

    case CREATE_SHIPMENT_SUCCESS: {
      const convos = state[state.context].conversations;
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          conversations: tweak(
            convos,
            (c) => c.id === action.payload.conversationId,
            addNewShipment(action.payload.shipment),
          ),
        },
      };
    }

    case GRANT_EXTENSION_SUCCESS: {
      const convos = state[state.context].conversations;
      return {
        ...state,
        [state.context]: {
          ...state[state.context],
          conversations: tweak(
            convos,
            (c) => c.listing.id === action.payload.listingId,
            modifyWithholdingInfo(action.payload.withholdingInfo),
          ),
        },
      };
    }

    default:
      return state;
  }
}
/*
Given a list of things `xs`, apply a transformation `transform` to each item
you care about, based on `condition`, leaving the rest alone. Do this without
mutating anything (assuming that `transform` doesn't mutate anything either).
*/

function tweak<X>(
  xs: Array<X>,
  condition: (arg0: X) => boolean,
  transform: (arg0: X) => X,
): Array<X> {
  return xs.map((x) => (condition(x) ? transform(x) : x));
}

function markAsRead(convo: Record<string, any>): Record<string, any> {
  return { ...convo, isRead: true };
}

function blockInterlocutor(convo: Record<string, any>): Record<string, any> {
  return setInterlocutorBlockedStatusTo(convo, true);
}

function unblockInterlocutor(convo: Record<string, any>): Record<string, any> {
  return setInterlocutorBlockedStatusTo(convo, false);
}

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'convo' implicitly has an 'any' type.
function setInterlocutorBlockedStatusTo(convo, b: boolean) {
  return { ...convo, interlocutor: { ...convo.interlocutor, isBlocked: b } };
}

function addNewShipment(shipment: Record<string, any>) {
  const newShipmentMessage = `${shipment.courierName}: ${shipment.trackingNumber}`;
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'convo' implicitly has an 'any' type.
  return (convo) => ({
    ...convo,
    listing: {
      ...convo.listing,
      shipments: [...(convo.listing.shipments || []), shipment],
      needsTracking: false,
    },
    activityLog: [
      ...convo.activityLog,
      {
        id: -1,
        // HACK: This should be an activityLogMessage id
        type: "shipment",
        message: newShipmentMessage,
        ...shipment,
      },
    ],
  });
}

function modifyWithholdingInfo(withholdingInfo: WithholdingInfo) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'convo' implicitly has an 'any' type.
  return (convo) => ({
    ...convo,
    listing: { ...convo.listing, withholdingInfo },
  });
}

function replaceShipmentWith(
  oldShipmentId: number,
  newShipment: Record<string, any>,
) {
  // TODO: don't mutate old activity logs here
  // instead, create and store new activity log when a shipment is created or updated
  // tricky: the existing activity logs DO NOT read from historical shipments
  const newShipmentMessage = `${newShipment.courierName}: ${newShipment.trackingNumber}`;
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'convo' implicitly has an 'any' type.
  return (convo) => ({
    ...convo,
    // NOTE: the id of the activityLog is the shipment id
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'log' implicitly has an 'any' type.
    activityLog: convo.activityLog.map((log) =>
      log.id === oldShipmentId
        ? { ...log, ...newShipment, message: newShipmentMessage }
        : log,
    ),
    listing: {
      ...convo.listing,
      shipments: tweak(
        convo.listing.shipments,
        (s) => s.id === oldShipmentId,
        replaceCompletelyWith(newShipment),
      ),
    },
  });
}

function replaceCompletelyWith(newThing: Record<string, any>) {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'oldThing' implicitly has an 'any' type.
  return (oldThing) => newThing;
}
