import type { Action } from "storefront/Action";

export const FOLLOW_USER_RESPONDED =
  "GrailedAPI.v1.Users.Follows.followUserResponded";

const followUserResponded: (arg0: number) => Action = (userId) => ({
  type: FOLLOW_USER_RESPONDED,
  payload: {
    userId,
  },
});

export default followUserResponded;
