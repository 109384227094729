import type { Id } from "storefront/lib/Id";
import type { Shipment } from "storefront/Shipment";

export const CREATE_SHIPMENT_SUCCESS: "CREATE_SHIPMENT_SUCCESS" =
  "CREATE_SHIPMENT_SUCCESS";
type Payload = {
  shipment: Shipment;
  conversationId?: Id;
  transactionId?: Id;
};
export type CreateShipmentAction = {
  type: typeof CREATE_SHIPMENT_SUCCESS;
  payload: Payload;
};
export const createShipmentSuccess = ({
  shipment,
  conversationId,
  transactionId,
}: Payload): CreateShipmentAction => {
  return {
    type: CREATE_SHIPMENT_SUCCESS,
    payload: {
      shipment,
      conversationId,
      transactionId,
    },
  };
};
