import { UserSelf } from "storefront/User";

export type Session = {
  currentUser: UserSelf | null | undefined;
  isUpdatingUser: boolean;
  modalIsOpen: boolean;
  initialUserFetched: boolean;
};

const session = (): Session => ({
  // TODO: This *should* be null, but needs to stay as {} until we have more bandwidth to fix all
  // of the places where it is always assumed to be {}.

  // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'UserSelf'.
  currentUser: {},
  isUpdatingUser: false,
  modalIsOpen: false,
  initialUserFetched: false,
});

export default session;
