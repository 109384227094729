import type { Reducer } from "redux";
import type { Action } from "storefront/Action";
import {
  TWO_FACTOR_AUTH_MOUNTED,
  TWO_FACTOR_RESPONSE_RECEIVED,
} from "../constants/action_types";
import type { Security } from "../types/Security";

const initialState = {
  isLoading: false,
  otpPresent: false,
};

// @ts-expect-error ts-migrate(2322) FIXME: Type '{ isLoading: boolean; otpPresent: boolean; }... Remove this comment to see the full error message
const security: Reducer<Security, Action> = (state = initialState, action) => {
  switch (action.type) {
    case TWO_FACTOR_AUTH_MOUNTED:
      return { ...state, isLoading: true };

    case TWO_FACTOR_RESPONSE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        otpPresent: action.payload.otpPresent,
      };

    default:
      return state;
  }
};

export default security;
