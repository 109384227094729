import type { Reducer } from "redux";
import { set } from "lodash/fp";
import { empty } from "storefront/Filters";
import type { Filters } from "storefront/Filters";
import type { Action } from "storefront/Action";
import {
  CLOSE_MANAGE_SAVED_FILTERS_MODAL,
  EDIT_SAVED_FILTER,
  OPEN_MANAGE_SAVED_FILTERS_MODAL,
  RENAME_SAVED_FILTER,
  SET_DESIGNER_SEARCH_QUERY,
  UPDATE_APPLIED_SEARCH_ID,
  UPDATE_FILTER_NAMES,
  UPDATE_SAVED_FILTERS,
  UPDATE_SORT,
} from "storefront/actions/constants";
import {
  FILTERING_DESIGNERS_SEARCH_CHANGED,
  FILTERING_DESIGNERS_SEARCH_CLEARED,
  FILTERING_DESIGNERS_SEARCH_FOCUSED,
  FILTERING_DESIGNERS_SEARCH_RESPONSE_RECEIVED,
} from "../constants/action_types";

/**
 * IMPORTANT
 * DEPRECATED
 */
const filtering: Reducer<Filters, Action> = (
  state = empty,
  { type, payload },
) => {
  switch (type) {
    case FILTERING_DESIGNERS_SEARCH_CHANGED:
      return set("designerSearchQuery", payload.query, state);

    case FILTERING_DESIGNERS_SEARCH_CLEARED:
      return set("designerSearchQuery", "", state);

    case FILTERING_DESIGNERS_SEARCH_FOCUSED:
      return set("designerSearchFocused", true, state);

    case FILTERING_DESIGNERS_SEARCH_RESPONSE_RECEIVED:
      return set("filteredDesigners", payload.results, state);

    case UPDATE_APPLIED_SEARCH_ID:
      return { ...state, appliedSearchId: payload.appliedSearchId };

    case UPDATE_FILTER_NAMES:
      return { ...state, savedSearches: payload.savedSearches };

    case EDIT_SAVED_FILTER:
      return {
        ...state,
        manageSavedFiltersModal: {
          isOpen: true,
          editingUUID: payload.uuid,
        },
      };

    case RENAME_SAVED_FILTER:
      return {
        ...state,
        manageSavedFiltersModal: {
          isOpen: true,
          editingUUID: null,
        },
      };

    case UPDATE_SAVED_FILTERS:
      return { ...state, savedSearches: payload.savedSearches };

    case SET_DESIGNER_SEARCH_QUERY:
      return { ...state, designerSearchQuery: payload.query };

    case OPEN_MANAGE_SAVED_FILTERS_MODAL:
      return {
        ...state,
        manageSavedFiltersModal: {
          ...state.manageSavedFiltersModal,
          isOpen: true,
        },
      };

    case CLOSE_MANAGE_SAVED_FILTERS_MODAL:
      return {
        ...state,
        manageSavedFiltersModal: {
          ...state.manageSavedFiltersModal,
          isOpen: false,
        },
      };

    case UPDATE_SORT:
      return { ...state, sort: payload.sort };

    default:
      return state;
  }
};

export default filtering;
