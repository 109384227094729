import type { Id } from "storefront/lib/Id";
import type { Shipment } from "storefront/Shipment";

export const EDIT_SHIPMENT_SUCCESS: "EDIT_SHIPMENT_SUCCESS" =
  "EDIT_SHIPMENT_SUCCESS";
type Payload = {
  oldShipmentId: Id;
  shipment: Shipment;
  conversationId?: Id;
  transactionId?: Id;
};
export type EditShipmentAction = {
  type: typeof EDIT_SHIPMENT_SUCCESS;
  payload: Payload;
};
export const editShipmentSuccess = ({
  oldShipmentId,
  shipment,
  conversationId,
  transactionId,
}: Payload): EditShipmentAction => {
  return {
    type: EDIT_SHIPMENT_SUCCESS,
    payload: {
      oldShipmentId,
      shipment,
      conversationId,
      transactionId,
    },
  };
};
