import type { Strata } from "storefront/Strata";
import * as Storage from "storefront/lib/Storage";
import { searchableStrata, fromWindow } from "storefront/Config/PublicConfig";
import isMobile from "../lib/is_mobile";
import * as Location from "./Location";

export type Homepage = {
  filtersExpanded: boolean;
  isSearchingSoldListings: boolean;
  searchableStrata: Array<Strata>;
};

const filtersExpanded = (w: typeof window): boolean => {
  if (isMobile(window)) return false;
  return (
    Storage.safelyGetItem(w.localStorage)(
      "homepage_filters_active_localstorage_key",
    ) !== "false"
  );
};

const isSearchingSoldListings = (w: typeof window): boolean =>
  Location.path(Location.fromWindow(w)) === "sold";

const searchableStrataFromWindow = (w: typeof window): Array<Strata> =>
  searchableStrata(fromWindow(w));

const homepage = (w: typeof window): Homepage => ({
  filtersExpanded:
    Location.path(Location.fromWindow(w)) === "feed" || filtersExpanded(w),
  isSearchingSoldListings: isSearchingSoldListings(w),
  searchableStrata: searchableStrataFromWindow(w),
});

export default homepage;
