import React from "react";
import { useTranslation } from "react-i18next";
import { StatusCode } from "storefront/pages/_error/StatusCode";

import styles from "storefront/components/FullPageError/FullPageError.module.scss";

type Props = { errorCode: StatusCode | "unknown" };

/**
 * The FullPageError component is intended for use when you want to display an error message that takes over the whole page; either with the header and footer or without them.
 *
 * If you want to display an error for a small component, this one is going to look weird.
 */
const FullPageError = ({ errorCode = "unknown" }: Props) => {
  const { t } = useTranslation();
  const contact = t(`error.${errorCode}.contact`);
  return (
    <div className={styles.errorPage}>
      <h2 className={styles.heading}>
        {errorCode !== "unknown" ? (
          <span className={styles.statusCode}>{errorCode}</span>
        ) : (
          <></>
        )}

        <span className={styles.title}>{t(`error.${errorCode}.title`)}</span>
      </h2>

      {contact ? <p className={styles.contact}>{contact}</p> : <></>}

      <img
        className={styles.image}
        src={t(`error.${errorCode}.imgSrc`)}
        alt={t(`error.${errorCode}.imgAlt`)}
      />
    </div>
  );
};

export default FullPageError;
