import type { Id } from "storefront/lib/Id";
import { get } from "storefront/GrailedAPI/request";

type Response = {
  data: {};
};

/**
 * @name GrailedAPI.v1.Internal.Users.getRelatedUsers
 * @param {number} id
 */
const getRelatedUsers: (arg0: Id) => Promise<Response> = (id) =>
  get(`/api/users/${id}/related_users`);

export default getRelatedUsers;
