import type { Reducer } from "redux";
import { uniqBy } from "lodash";
import type { Action } from "storefront/Action";
import { emptyGroups } from "storefront/InternalTools/Groups";
import type { InternalTools } from "storefront/InternalTools";
import {
  BAN_IP,
  UNBAN_IP,
  INTERNAL_TOOLS_CONTENT_REQUEST,
  INTERNAL_TOOLS_CONTENT_REQUEST_SUCCESS,
  INTERNAL_TOOLS_GROUPS_REQUEST,
  INTERNAL_TOOLS_GROUPS_REQUEST_SUCCESS,
  INTERNAL_TOOLS_FETCH_RELATED_USERS_SUCCESS,
  FETCH_TRUST_SCORES_REQUEST,
  FETCH_TRUST_SCORES_ERROR,
  FETCH_TRUST_SCORES_SUCCESS,
  CREATE_TRUST_SCORE_REQUEST,
  CREATE_TRUST_SCORE_ERROR,
  CREATE_TRUST_SCORE_SUCCESS,
  FETCH_MERCHANT_REQUEST,
  FETCH_MERCHANT_SUCCESS,
  FETCH_MERCHANT_ERROR,
} from "../constants/action_types";

const initialState: InternalTools = {
  bannedIPs: [],
  capsules: {
    capsules: {
      data: [],
      hasMoreCapsules: false,
      nextPage: "",
      isLoading: false,
    },
    currentCapsule: null,
  },
  focusedCategorySavedSearch: null,
  focusedCategory: null,
  merchant: {
    sellerIds: [],
    buyerIds: [],
  },
  trustScore: {
    scoreOptions: [],
    reasonOptions: [],
  },
  items: {
    content: {},
    trustScores: {
      isLoading: false,
      error: false,
      data: [],
      metadata: {},
      hasMore: false,
    },
  },
  categorySearches: [],
  error: null,
  groups: emptyGroups,
  isLoading: false,
  isLoadingRefund: false,
  refundError: null,
  relatedUsers: [],
  flags: {
    metadata: {},
    data: [],
  },
  hasMoreFlags: false,
  isLoadingFlags: false,
};

const internalTools: Reducer<InternalTools, Action> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case BAN_IP:
      return { ...state, bannedIPs: [...state.bannedIPs, action.payload.ip] };

    case UNBAN_IP:
      return {
        ...state,
        bannedIPs: state.bannedIPs.filter((ip) => ip !== action.payload.ip),
      };

    case INTERNAL_TOOLS_CONTENT_REQUEST:
      return {
        ...state,
        items: {
          ...state.items,
          content: {
            ...state.items.content,
            [action.payload.category]: {
              ...state.items.content[action.payload.category],
              isLoading: true,
            },
          },
        },
      };

    case INTERNAL_TOOLS_CONTENT_REQUEST_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          content: {
            ...state.items.content,
            [action.payload.category]: {
              ...state.items.content[action.payload.category],
              isLoading: false,
              hasMoreContent: action.payload.content.length > 0,
              data: uniqBy(
                [
                  ...state.items.content[action.payload.category].data,
                  ...action.payload.content,
                ],
                "id",
              ),
              metadata: action.payload.metadata,
            },
          },
        },
      };

    case INTERNAL_TOOLS_GROUPS_REQUEST:
      return { ...state, isLoading: true };

    case INTERNAL_TOOLS_GROUPS_REQUEST_SUCCESS:
      return { ...state, isLoading: false, groups: action.payload.groups };

    case FETCH_MERCHANT_REQUEST:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          sellerIds: [...state.merchant.sellerIds],
          buyerIds: [...state.merchant.buyerIds],
        },
      };

    case FETCH_MERCHANT_SUCCESS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          sellerIds: [
            ...state.merchant.sellerIds,
            ...action.payload.data.sellerIds,
          ],
          buyerIds: [
            ...state.merchant.buyerIds,
            ...action.payload.data.buyerIds,
          ],
        },
      };

    case FETCH_MERCHANT_ERROR:
      return { ...state, error: action.payload.error };

    case FETCH_TRUST_SCORES_REQUEST:
      return {
        ...state,
        items: {
          ...state.items,
          trustScores: { ...state.items.trustScores, isLoading: true },
        },
      };

    case FETCH_TRUST_SCORES_ERROR:
      return {
        ...state,
        items: {
          ...state.items,
          trustScores: {
            ...state.items.trustScores,
            error: true,
            isLoading: false,
          },
        },
      };

    case FETCH_TRUST_SCORES_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          trustScores: {
            ...state.items.trustScores,
            data: uniqBy(
              [
                ...state.items.trustScores.data,
                ...action.payload.data.trustScores,
              ],
              "id",
            ),
            metadata: action.payload.metadata,
            hasMore: action.payload.data.trustScores > 0,
            isLoading: false,
          },
        },
      };

    case CREATE_TRUST_SCORE_REQUEST:
      return {
        ...state,
        items: {
          ...state.items,
          trustScores: { ...state.items.trustScores, isLoading: true },
        },
      };

    case CREATE_TRUST_SCORE_ERROR:
      return {
        ...state,
        items: {
          ...state.items,
          trustScores: {
            ...state.items.trustScores,
            error: true,
            isLoading: false,
          },
        },
      };

    case CREATE_TRUST_SCORE_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          trustScores: {
            ...state.items.trustScores,
            data: [
              ...[action.payload.trustScore],
              ...state.items.trustScores.data,
            ],
            isLoading: false,
          },
        },
      };

    case INTERNAL_TOOLS_FETCH_RELATED_USERS_SUCCESS:
      return { ...state, relatedUsers: action.payload.data };

    default:
      return state;
  }
};

export default internalTools;
