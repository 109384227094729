import { SavedSearch } from "storefront/SavedSearch";
import { get } from "storefront/GrailedAPI/request";

export type SavedSearchesSuccess = Array<SavedSearch>;

type Response = { data: SavedSearchesSuccess };

/**
 * @name GrailedAPI.v1.SavedSearches.getAllSavedSearches
 * @return {Promise<Array<SavedSearch>>} a promise that resolves to
 * all the SavedSearches a user is subscribed to
 */

const unpack = (res: Response): SavedSearchesSuccess => res.data;

const getAllSavedSearches: () => Promise<SavedSearchesSuccess> = () =>
  get("/api/saved_searches")
    .then(unpack)
    .catch((err) => {
      if (err?.response?.status === 401) return []; // if we're logged out
      throw err;
    });

export default getAllSavedSearches;
