import { update, pull, concat } from "lodash/fp";
import {
  UNFAVORITE_LISTING_REQUEST,
  UNFAVORITE_LISTING_ERROR,
  FAVORITE_LISTING_REQUEST,
  FAVORITE_LISTING_ERROR,
} from "storefront/actions/constants";
import { empty as emptyFollowing } from "storefront/Followings";
import {
  CHANGE_HEADER_ACTIVE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  FETCH_INITIAL_USER_SUCCESS,
  OPEN_MODAL,
  CLOSE_MODAL,
} from "../constants/action_types";
import { FOLLOW_USER_RESPONDED } from "../lib/GrailedAPI/v1/Users/Follows/Actions/followUserResponded";
import { UNFOLLOW_USER_RESPONDED } from "../lib/GrailedAPI/v1/Users/Follows/Actions/unfollowUserResponded";

export default function session(
  state: Record<string, any> = {},
  action: Record<string, any>,
): Record<string, any> {
  switch (action.type) {
    case FOLLOW_USER_RESPONDED: {
      const { userId } = action.payload;
      return update("currentUser.following.users", concat(userId), state);
    }

    case UNFOLLOW_USER_RESPONDED: {
      const { userId } = action.payload;
      return update("currentUser.following.users", pull(userId), state);
    }

    case CHANGE_HEADER_ACTIVE:
      return { ...state, activeHeader: action.payload.appName };

    case UPDATE_USER_REQUEST:
      return { ...state, isUpdatingUser: true };

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        isUpdatingUser: false,
        currentUser: { ...state.currentUser, ...action.payload },
      };

    case UPDATE_USER_FAILURE:
      return { ...state, isUpdatingUser: false };

    case FETCH_INITIAL_USER_SUCCESS:
      return { ...state, initialUserFetched: true };

    case FAVORITE_LISTING_REQUEST:
    case UNFAVORITE_LISTING_ERROR: {
      const { following = emptyFollowing } = state.currentUser;
      const { followedListings = [] } = state.currentUser;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          following: {
            ...following,
            listings: [...following.listings, action.payload.listingId],
          },
          followedListings: [...followedListings, action.payload.listingId],
        },
      };
    }

    case UNFAVORITE_LISTING_REQUEST:
    case FAVORITE_LISTING_ERROR: {
      const { following = emptyFollowing } = state.currentUser;
      const { followedListings = [] } = state.currentUser;
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          following: {
            ...following,
            listings: [
              ...following.listings.filter(
                // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'l' implicitly has an 'any' type.
                (l) => l !== action.payload.listingId,
              ),
            ],
          },
          followedListings: [
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'l' implicitly has an 'any' type.
            ...followedListings.filter((l) => l !== action.payload.listingId),
          ],
        },
      };
    }

    case OPEN_MODAL:
      return { ...state, modalIsOpen: true };

    case CLOSE_MODAL:
      return { ...state, modalIsOpen: false };

    default:
      return state;
  }
}
