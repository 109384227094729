import type { Conversation } from "storefront/Conversation";
import * as Storage from "storefront/lib/Storage";

type Id = string | number;
type ContextName = "buying" | "selling";
type Context = {
  conversations: Array<Conversation>;
  nextPage: number;
  loadingConversations: boolean;
  ajaxRequestLoading: boolean;
  expandedConversationIds: Array<Id>;
  expandedSettings: number | null | undefined;
  activeConversationIds: Array<Id>;
  slidDownConversationIds: Array<Id>;
  unreadCount: number;
};
export type Conversations = {
  context: ContextName;
  isViewingArchived: boolean;
  offerModalIsOpen: boolean;
  offerModalConversationId: Id | null | undefined;
  replyModalIsOpen: boolean;
  replyModalConversationId: Id | null | undefined;
  individualLoadingConversations: Array<number>;
  buying: Context;
  selling: Context;
};

const context = (w: typeof window): ContextName => {
  const contextFromStorage = Storage.safelyGetItem(w.localStorage)(
    "conversations_context",
  );
  return contextFromStorage === "selling" ? "selling" : "buying";
};

const conversations = (w: typeof window): Conversations => ({
  context: context(w),
  isViewingArchived: window.location.pathname.includes("archived"),
  offerModalIsOpen: false,
  offerModalConversationId: null,
  replyModalIsOpen: false,
  replyModalConversationId: null,
  individualLoadingConversations: [],
  buying: {
    conversations: [],
    nextPage: 1,
    loadingConversations: false,
    ajaxRequestLoading: false,
    expandedConversationIds: [],
    expandedSettings: null,
    activeConversationIds: [],
    slidDownConversationIds: [],
    unreadCount: 0,
  },
  selling: {
    conversations: [],
    nextPage: 1,
    loadingConversations: false,
    ajaxRequestLoading: false,
    expandedConversationIds: [],
    activeConversationIds: [],
    expandedSettings: null,
    slidDownConversationIds: [],
    unreadCount: 0,
  },
});

export default conversations;
