import type { Reducer } from "redux";
import type { Action } from "storefront/Action";
import {
  FETCH_FITTING_ROOM_ERROR,
  FETCH_FITTING_ROOM_REQUEST,
  FETCH_FITTING_ROOM_SUCCESS,
  FITTING_ROOM_FOLLOW_SELLER,
  FITTING_ROOM_SORT_UPDATED,
  FITTING_ROOM_UNFOLLOW_SELLER,
  MUTE_LISTING_ERROR,
  MUTE_LISTING_REQUEST,
  MUTE_LISTING_SUCCESS,
  NO_MORE_LISTINGS_FOR_FITTING_ROOM,
  UNMUTE_LISTING_ERROR,
  UNMUTE_LISTING_REQUEST,
  UNMUTE_LISTING_SUCCESS,
  VIEW_CAPSULES,
  VIEW_GRAILS,
  VIEW_SEARCHES,
  VIEW_SELLERS,
  VIEW_DESIGNERS,
} from "storefront/actions/constants";
import type { FittingRoom } from "storefront/FittingRoom";
import { AlgoliaPager } from "storefront/Algolia/AlgoliaPager";
import { fittingRoomPager } from "../lib/GrailedAPI/v1/Users/Follows";
// This is not the initial state, its being use as a "default" state which is not necessary.
const initialState: FittingRoom = {
  fittingRoomUserId: null,
  followedSellers: [],
  viewing: "grails",
  mutedListingIds: [],
  muteRequestId: null,
  grails: {
    loading: false,
    noMoreListings: false,
    listings: [],
  },
  sellers: {
    loading: false,
    noMoreListings: false,
    listings: [],
  },
  capsules: {
    loading: false,
    noMoreListings: false,
    listings: [],
  },
  searches: {
    loading: false,
    noMoreListings: true,
    // we dont fetch listings on searches
    listings: [],
  },
  designers: {
    loading: false,
    noMoreListings: true,
    listings: [],
  },
};

const fittingRoom: Reducer<FittingRoom, Action> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case FETCH_FITTING_ROOM_REQUEST: {
      const { destination } = action.payload;
      return {
        ...state,
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [destination]: { ...state[destination], loading: true },
      };
    }

    case FETCH_FITTING_ROOM_SUCCESS: {
      const { listings, destination } = action.payload;
      return {
        ...state,
        [destination]: {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...state[destination],
          loading: false,
          error: undefined,
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          listings: [...state[destination].listings, ...listings],
        },
      };
    }

    case FETCH_FITTING_ROOM_ERROR: {
      const { error, destination } = action.payload;
      return {
        ...state,
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [destination]: { ...state[destination], error, loading: false },
      };
    }

    case NO_MORE_LISTINGS_FOR_FITTING_ROOM: {
      const { viewing } = action.payload;
      return {
        ...state,
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        [viewing]: { ...state[viewing], loading: false, noMoreListings: true },
      };
    }

    case MUTE_LISTING_REQUEST: {
      return { ...state, muteRequestId: action.payload.listingId };
    }

    case MUTE_LISTING_SUCCESS: {
      return {
        ...state,
        muteRequestId: null,
        mutedListingIds: state.mutedListingIds.concat([
          action.payload.listingId,
        ]),
      };
    }

    case MUTE_LISTING_ERROR: {
      return { ...state, muteRequestId: null };
    }

    case UNMUTE_LISTING_REQUEST: {
      return { ...state, muteRequestId: action.payload.listingId };
    }

    case UNMUTE_LISTING_SUCCESS: {
      return {
        ...state,
        muteRequestId: null,
        mutedListingIds: state.mutedListingIds.filter(
          (id) => id !== action.payload.listingId,
        ),
      };
    }

    case UNMUTE_LISTING_ERROR: {
      return { ...state, muteRequestId: null };
    }

    case VIEW_GRAILS: {
      return { ...state, viewing: "grails" };
    }

    case VIEW_SEARCHES: {
      return { ...state, viewing: "searches" };
    }

    case VIEW_SELLERS: {
      return { ...state, viewing: "sellers" };
    }

    case VIEW_CAPSULES: {
      return { ...state, viewing: "capsules" };
    }

    case VIEW_DESIGNERS: {
      return { ...state, viewing: "designers" };
    }

    case FITTING_ROOM_FOLLOW_SELLER: {
      const { seller } = action.payload;
      const followedSellers = [...state.followedSellers, seller];
      return {
        ...state,
        followedSellers,
        sellers: {
          ...state.sellers,
          loading: false,
          noMoreListings: false,
          listings: [],
          pager: AlgoliaPager.listingsSoldBy(followedSellers.map((s) => s.id)),
        },
      };
    }

    case FITTING_ROOM_UNFOLLOW_SELLER: {
      const { seller } = action.payload;
      const followedSellers = state.followedSellers.filter(
        (s) => s.id !== seller.id,
      );
      return {
        ...state,
        followedSellers,
        sellers: {
          ...state.sellers,
          loading: false,
          noMoreListings: false,
          listings: [],
          pager: AlgoliaPager.listingsSoldBy(followedSellers.map((s) => s.id)),
        },
      };
    }

    case FITTING_ROOM_SORT_UPDATED: {
      if (!state.fittingRoomUserId) return state;
      return {
        ...state,
        grails: {
          ...state.grails,
          activeSort: action.payload.sort,
          pager: fittingRoomPager(state.fittingRoomUserId, action.payload.sort),
          listings: [],
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default fittingRoom;
