/* eslint-disable no-restricted-syntax */
import { isEmpty, keys, zipObject } from "lodash";
import i18n from "i18next";
import matchSoldPage from "storefront/matchSoldPage";

type PriceModifier = "sold_" | "";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'minPrice' implicitly has an 'any' type.
// eslint-disable-next-line consistent-return
function priceFilter(priceModifier: PriceModifier = "", minPrice, maxPrice) {
  if (maxPrice && minPrice) {
    return `${priceModifier}price:${minPrice} TO ${maxPrice}`;
  }

  if (maxPrice) {
    return `${priceModifier}price<=${maxPrice}`;
  }

  if (minPrice) {
    return `${priceModifier}price>=${minPrice}`;
  }
}

function* zipCategoryAndSizeSelections(
  categories: Record<string, any>,
  sizes: Record<string, any>,
) {
  if (isEmpty(categories) && isEmpty(sizes)) {
    return;
  }

  const sizeCategories = zipObject(keys(sizes));
  // eslint-disable-next-line no-param-reassign
  categories = { ...sizeCategories, ...(categories || {}) };

  // eslint-disable-next-line guard-for-in
  for (const rootCategory in categories) {
    const selectedSubcategories = categories[rootCategory];
    const selectedCategorySizes = sizes[rootCategory];

    if (isEmpty(selectedSubcategories) && isEmpty(selectedCategorySizes)) {
      yield `category:'${rootCategory}'`;
    } else if (isEmpty(selectedCategorySizes)) {
      for (const subcategory of selectedSubcategories) {
        yield `category_path:'${rootCategory}.${subcategory}'`;
      }
    } else if (isEmpty(selectedSubcategories)) {
      for (const size of selectedCategorySizes) {
        yield `category_size:'${rootCategory}.${size}'`;
      }
    } else {
      for (const subcategory of selectedSubcategories) {
        for (const size of selectedCategorySizes) {
          yield `category_path_size:'${rootCategory}.${subcategory}.${size}'`;
        }
      }
    }
  }
}

export function kDotVsToObject(kDotVs: Array<string>) {
  const result = {};

  if (!kDotVs) {
    return result;
  }

  // kDotV => 'key.value' notation
  for (const kDotV of kDotVs) {
    // eslint-disable-next-line prefer-const
    let [k, ...v] = kDotV.split(".");
    // hacky fix for shoe half-sizes (eg. 'sneakers.9.5')... Array.join produces an empty string on empty arrays!
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'string[]'... Remove this comment to see the full error message
    v = v.join(".");

    if (v) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[k] = [v, ...(result[k] || [])]; // only add value if a selection exists
    } else {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[k] = result[k] || []; // if there are no selections for this category, map to existing entry or empty array
    }
  }

  return result;
}
export function filtersToAlgoliaQuery(
  filters: Record<string, any> = {},
): string {
  const sizeSelections = kDotVsToObject(filters.sizes);
  const categorySelections = kDotVsToObject(filters.categoryPaths);
  const priceModifier: PriceModifier = matchSoldPage() ? "sold_" : "";
  let totalDesigners = "";

  if (filters.designers) {
    if (window.location.pathname.match(/collaborations/)) {
      totalDesigners = filters.designers
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
        .map((val) => `designers.id:${val}`)
        .join(" AND ");
    } else {
      totalDesigners = filters.designers
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
        .map((val) => `designers.id:${val}`)
        .join(" OR ");
    }
  }

  const a = [
    totalDesigners,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
    (filters.badges || []).map((val) => `badges:'${val}'`).join(" OR "),
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
    (filters.strata || []).map((val) => `strata:'${val}'`).join(" OR "),
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
    (filters.locations || []).map((val) => `location:'${val}'`).join(" OR "),
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
    (filters.ids || []).map((val) => `objectID:'${val}'`).join(" OR "),
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
    (filters.users || []).map((val) => `user.id:'${val}'`).join(" OR "),
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
    (filters.skuIds || []).map((val) => `sku_id=${val}`).join(" OR "),
    priceFilter(priceModifier, filters.minPrice, filters.maxPrice),
    [...zipCategoryAndSizeSelections(categorySelections, sizeSelections)].join(
      " OR ",
    ),
    `marketplace:${i18n.t("MARKETPLACE_ID")}`,
  ]
    .filter((condition) => condition)
    .map((condition) => `(${condition})`)
    .join(" AND ");
  return a;
}
