import invariant from "assert";

/**
 * @description
 * Given a function `f` that returns a promise, keep trying to run it until it succeeds, yielding
 * each rejection along the way. Yielding the rejections (as opposed to ignoring them and just
 * retrying ourself) lets our caller decide whether they want to retry or not.
 */
export function yieldRejectionsUntilSuccess<Args extends Array<any>, R>(
  f: (...args: Args) => Promise<R>,
): (...args: Args) => AsyncGenerator<
  {
    rejection: Error;
    tag: "failure";
  },
  R,
  void
> {
  return async function* yieldRejectionsAsyncGenerator(...args: Args) {
    while (true) {
      try {
        /* eslint-disable-next-line no-await-in-loop */
        return await f(...args);
      } catch (rejection) {
        yield {
          rejection,
          tag: "failure",
        };
      }
    }

    // eslint-disable-next-line no-unreachable
    invariant(false, "Convince flow we can never get here.");
  };
}
