let memo: boolean | null | undefined = null;

/**
 * DEPRECATED!!
 * PLEASE STOP USING. ty!
 * @name DEPRECATED isMobile
 * @description DEPRECATED A memoizable function that determines whether the current window is a mobile window.
 * If there is no window or window.document, then the current window is not a mobile window. The
 * server places a detector element in the page which is set to display: none when on mobile, so we
 * check that to see if the window is a mobile window.
 */
const isMobile = (
  w: typeof window | null | undefined = window,
  memoize: boolean | null | undefined = true,
): boolean => {
  if (memoize && memo !== null) return !!memo;

  if (!w || !w.document) {
    if (memoize) memo = false;
    return false;
  }

  const detector = w.document.querySelector(".mobile-detector");

  if (detector) {
    const style = w.getComputedStyle(detector);
    const display = style.getPropertyValue("display");
    const isDisplayNone = display === "none";
    if (memoize) memo = isDisplayNone;
    return isDisplayNone;
  }

  if (memoize) memo = false;
  return false;
};

export default isMobile;
