import type { Action } from "storefront/Action";

export const UNFOLLOW_USER_RESPONDED =
  "GrailedAPI.v1.Users.Follows.unfollowUserResponded";

const unfollowUserResponded: (arg0: number) => Action = (userId) => ({
  type: UNFOLLOW_USER_RESPONDED,
  payload: {
    userId,
  },
});

export default unfollowUserResponded;
