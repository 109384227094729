import { addMinutes, isAfter } from "date-fns/fp";
import Cookie from "js-cookie";
import { compose, map, split, filter, fromPairs } from "lodash/fp";
import { isString, isEmpty } from "lodash";

const utmParamsCookieName = "utm_params";
const utmExpiresAtName = "utm_cookie_expires_at";
const thirtyMinutes = 1 / 2 / 24;

// These are the query params we'll be storing in a cookie for use in later requests we
// make to the server. If we need to add additional params here, we'll also need to
// add them on the server where we use the values stored in `UtmParamsStore`.
const UTM_PARAMS = [
  "utm_campaign",
  "utm_content",
  "utm_medium",
  "utm_referrer",
  "utm_source",
  "utm_term",
];

const utmParamsHaveExpired = (expiration: string | null | undefined): boolean =>
  !!expiration &&
  isString(expiration) &&
  isAfter(new Date(expiration))(new Date());

/**
 * @name getUTMParams
 * @description Get UTM params from window
 */
export const getUTMParams = (search: string): Record<string, string> => {
  if (isEmpty(search)) return {};
  const params = search.slice(1).split("&");
  const acceptedUTMs = new RegExp(UTM_PARAMS.join("|"));
  return compose([
    fromPairs,
    filter(([key, _value]) => acceptedUTMs.test(key)),
    map(split("=")),
  ])(params);
};

/**
 * @name getUTMExpiresAtItem
 * @description Get expires at date from local storage if there is one
 */
const getUTMExpiresAtItem: () => string | null = () =>
  window.localStorage.getItem(utmExpiresAtName);

/**
 * @name maybeRemoveUTMExpirationItem
 * @description Checks to see if we need to clear local storage
 * @param {?string}
 */
const maybeRemoveUTMExpirationItem = (
  expiration: string | null | undefined,
): void => {
  if (!utmParamsHaveExpired(expiration)) return;
  window.localStorage.removeItem(utmExpiresAtName);
};

/**
 * @name handleExpiredUTMs
 * @description Checks to see if we need to clear a user's UTM params
 * @param {?string}
 */
const handleExpiredUTMs = (expiration: string | null | undefined): void => {
  maybeRemoveUTMExpirationItem(expiration);
};

/**
 * @name setUTMExpirationItem
 * @description Set local storage item with expiration date
 */
const setUTMExpirationItem = (): void => {
  const fromNow = addMinutes(30)(new Date());
  window.localStorage.setItem(utmExpiresAtName, fromNow.toString());
};

/**
 * @name getUTMParamsCookie
 * @description Get UTM cookie if there is one
 */
const getUTMParamsCookie: () => string | void = () =>
  Cookie.get(utmParamsCookieName);

/**
 * @name setUTMParamsCookie
 * @description Given some logic, set or do not set the cookie:
 * If there is a cookie
 *  return (let the cookie expire)
 * If there is no cookie
 *  and utmParams, set cookie
 *  and no utmParams, return (nothing to set)
 */
const setUTMParamsCookie: () => void = () => {
  const utmCookie = getUTMParamsCookie();
  const utmParams = getUTMParams(window.location.search);
  const utmExpirationItem = getUTMExpiresAtItem();
  handleExpiredUTMs(utmExpirationItem);
  if (isString(utmCookie)) return;
  if (isEmpty(utmParams)) return;
  setUTMExpirationItem();
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Record<string, any>' is not assi... Remove this comment to see the full error message
  Cookie.set(utmParamsCookieName, utmParams, {
    expires: thirtyMinutes,
  });
};

export default setUTMParamsCookie;
