import { find } from "lodash/fp";
import { UPDATE_SAVED_FILTERS } from "storefront/actions/constants";
import getInitialCurrentUserFromAPI from "storefront/InitialCurrentUser/getFromAPI";
import { updateUserSuccessActionCreator } from "storefront/_app/UpdateUserSuccessAction";
import { fetchInitialUserSuccessActionCreator } from "storefront/_app/FetchInitialUserSuccessAction";
import { fetchUnreadCountsSuccessActionCreator } from "storefront/_app/FetchUnreadCountsSuccessAction";
import getUnreadCounts from "storefront/GrailedAPI/v1/Conversations/getUnreadCounts";
import {
  CHANGE_HEADER_ACTIVE,
  UPDATE_USER_REQUEST,
  OPEN_MODAL,
  CLOSE_MODAL,
} from "../constants/action_types";

export function changeHeaderActive(appName: any) {
  return {
    type: CHANGE_HEADER_ACTIVE,
    payload: {
      appName,
    },
  };
}
export function setCurrentUserUnreadCounts() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { currentUser } = state.session;

    // only lookup unread counts if user is logged in
    if (currentUser.id) {
      const unreadCounts = await getUnreadCounts();
      dispatch(fetchUnreadCountsSuccessActionCreator(unreadCounts));
    }
  };
}
export function setCurrentUserSavedFilters() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const { currentUser } = state.session;

    // only lookup saved searches ("saved filters") if the user is logged in
    if (currentUser.id) {
      const { savedSearches } = currentUser;

      if (state.filters) {
        const { appliedSearchId } = state.filters;
        const activeSearch = appliedSearchId
          ? find(
              {
                uuid: appliedSearchId,
              },
              savedSearches,
            )
          : {};
        // NOTE(julian): user has a saved filter if their saved filter is not the default filter.
        const hasSavedFilter =
          savedSearches &&
          savedSearches[0] &&
          !!Object.keys(savedSearches[0].filters).length;
        dispatch({
          type: UPDATE_SAVED_FILTERS,
          payload: {
            activeSearch,
            savedSearches,
            hasSavedFilter,
          },
        });
      }
    }
  };
}
// This is called when the app boots, it makes a request to the api to get the currentUser
// given the client side credentials (if they exist)
export function setCurrentUser() {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });

    try {
      const currentUser = await getInitialCurrentUserFromAPI();
      dispatch(updateUserSuccessActionCreator(currentUser));
      dispatch(fetchInitialUserSuccessActionCreator());
      dispatch(setCurrentUserUnreadCounts());
      dispatch(setCurrentUserSavedFilters());
    } catch (err) {
      // failed to authenticate
    }
  };
}
export function openModal() {
  return {
    type: OPEN_MODAL,
    payload: {},
  };
}
export function closeModal() {
  return {
    type: CLOSE_MODAL,
    payload: {},
  };
}
