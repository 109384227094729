import type { Store } from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../../../client/reducers";
import createInitialState from "../../../client/createInitialState";
import { bindActions } from "../../../client/containers";

export const createEnhancedStore = (
  reducer: (...args: Array<any>) => any,
  initialState: Record<string, any>,
): Store => {
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers =
    // @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' do... Remove this comment to see the full error message
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancers = applyMiddleware(thunkMiddleware);
  const enhancedStoreCreator = composeEnhancers(enhancers)(createStore);
  return enhancedStoreCreator(reducer, initialState);
};

export const initialState = createInitialState();
const store = createEnhancedStore(rootReducer, initialState);

// TODO: Stop doing this.
export const actions = bindActions(store);

export default store;
