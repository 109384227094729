import { fromWindow } from "storefront/Config/PublicConfig";
import type { SellForm } from "storefront/SellForm";

// some values used in sell form
const sellForm = (w: typeof window): SellForm => {
  const { designersRequiringSneakerId, listingMinPrice, listingMaxPrice } =
    fromWindow(w);
  return {
    designersRequiringSneakerId,
    maxPrice: listingMaxPrice,
    minPrice: listingMinPrice,
  };
};

export default sellForm;
