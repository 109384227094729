import { $PropertyType } from "utility-types";
import { camelize } from "camelscore";
import { toNumber } from "lodash/fp";
import type { Strata } from "storefront/Strata";
import * as JSON from "storefront/lib/JSON";

type StrataValue = $PropertyType<Strata, "value">;
type Id = string | number;
type Filters = {
  badges: Array<string>;
  categoryPaths: Array<string>;
  designers: Array<Id>;
  designersAnd: Array<Id>;
  locations: Array<string>;
  maxPrice: number;
  minPrice: number;
  sizes: Array<string>;
  strata: Array<StrataValue>;
  name?: string;
};
export type Search = {
  defaultName: string | null | undefined;
  filters: Filters;
  query: string;
  indexName: string;
  urlPath: string | null | undefined;
  uuid: string;
  page?: string;
};
export const maybeFromWindow = ({
  location,
  SEARCH,
}: typeof window): Search | null | undefined => {
  if (location.hash !== "") {
    const cleanHash = location.hash.slice(1); // Removes # from start of hash.

    const decodedHash = decodeURIComponent(cleanHash);
    return JSON.safelyParse(decodedHash);
  }

  if (Object.keys(SEARCH || {}).length) {
    return camelize(SEARCH);
  }

  return null;
};
export const maybeFilters: (
  arg0: Search | null | undefined,
) => Filters | null | undefined = (search) => (search ? search.filters : null);
export const maybeQuery: (
  arg0: Search | null | undefined,
) => string | null | undefined = (search) => (search ? search.query : null);
export const maybeIndexName: (
  arg0: Search | null | undefined,
) => string | null | undefined = (search) => (search ? search.indexName : null);
export const maybePage: (
  arg0: Search | null | undefined,
) => number | null | undefined = (search) =>
  search ? toNumber(search.page) - 1 : null;
