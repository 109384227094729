/*
 * usage:
 *  timeout(new Promise(...), wait)
 *    .then(...)
 *    .catch(err => if ( err.message === 'TIMEOUT' ) { ... })
 */
export default function <T>(promise: Promise<T>, wait = 15000): Promise<T> {
  return new Promise((resolve, reject) => {
    setTimeout(() => reject(new Error("TIMEOUT")), wait);
    promise.then(resolve, reject);
  });
}
