import type { Config } from "storefront/Config";
import { fromWindow } from "storefront/Config/PublicConfig";

const config = (w: typeof window): Config => {
  const { facebookAppId, countries, algolia, stratas, badges } = fromWindow(w);
  return {
    algolia,
    algoliaIndexes: algolia.indexes.listings,
    countries,
    facebookAppId,
    stratas,
    badges,
  };
};

export default config;
