import getAllSavedSearches from "../GrailedAPI/v1/SavedSearches/getAllSavedSearches";
import renameSavedSearch from "../GrailedAPI/v1/SavedSearches/rename";
import unsubscribeSavedSearch from "../GrailedAPI/v1/SavedSearches/unsubscribe";
import {
  CLOSE_MANAGE_SAVED_FILTERS_MODAL,
  EDIT_SAVED_FILTER,
  OPEN_MANAGE_SAVED_FILTERS_MODAL,
  RENAME_SAVED_FILTER,
  UPDATE_APPLIED_SEARCH_ID,
  UPDATE_FILTER_NAMES,
  UPDATE_SAVED_FILTERS,
} from "./constants";

/**
 * IMPORTANT
 * DEPRECATED
 */
export function openManageSavedFiltersModal() {
  return {
    type: OPEN_MANAGE_SAVED_FILTERS_MODAL,
  };
}
export function closeManageSavedFiltersModal() {
  return {
    type: CLOSE_MANAGE_SAVED_FILTERS_MODAL,
  };
}
// Use the window path to set the app state's "appliedSearchId"
export function setCurrentAppliedSearchId() {
  return (dispatch: (...args: Array<any>) => any) => {
    const splitPaths = window.location.pathname.split("/");
    const path = splitPaths[1];
    let appliedSearchId;

    if (path === "designers" || path === "collaborations") {
      appliedSearchId = splitPaths[3];
    } else {
      appliedSearchId = splitPaths[2];
    }

    if (appliedSearchId) {
      dispatch({
        type: UPDATE_APPLIED_SEARCH_ID,
        payload: {
          appliedSearchId,
        },
      });
    }
  };
}
export function updateNotificationSettings() {
  return (dispatch: any) => {
    getAllSavedSearches().then((savedSearches) => {
      dispatch({
        type: UPDATE_SAVED_FILTERS,
        payload: {
          savedSearches,
        },
      });
    });
  };
}
export function editSavedFilter(uuid: string) {
  return {
    type: EDIT_SAVED_FILTER,
    payload: {
      uuid,
    },
  };
}
export function renameSavedFilter(uuid: string, name: string) {
  return (dispatch: any) =>
    renameSavedSearch(uuid, name)
      .then(() =>
        dispatch({
          type: RENAME_SAVED_FILTER,
        }),
      )
      .then(getAllSavedSearches)
      .then((savedSearches) =>
        dispatch({
          type: UPDATE_FILTER_NAMES,
          payload: {
            savedSearches,
          },
        }),
      );
}
export function deleteSavedFilter(uuid: string) {
  return (dispatch: any) => {
    unsubscribeSavedSearch(uuid)
      .then(getAllSavedSearches)
      .then((savedSearches) =>
        dispatch({
          type: UPDATE_SAVED_FILTERS,
          payload: {
            savedSearches,
          },
        }),
      );
  };
}
