import { Id } from "storefront/lib/Id";
import { Conversation } from "storefront/Conversation";
import {
  ActualGrailedAPIHeavyListing,
  formatGrailedAPIHeavyListing,
} from "storefront/Listing/formatListing";
import { get } from "storefront/GrailedAPI/request";

export type Metadata = {
  unreadCount: number;
};

/**
 * https://www.grailed.com/internal/api-docs#operation/getConversation
 */
type ConversationFromAPI = Omit<Conversation, "listing"> & {
  listing: ActualGrailedAPIHeavyListing;
};

type Response = {
  data: ConversationFromAPI;
  metadata: Metadata;
};

const unpack = (res: Response): [Conversation, Metadata] => {
  const { data, metadata } = res;
  const conversation = {
    ...data,
    listing: formatGrailedAPIHeavyListing(data.listing),
  };
  return [conversation, metadata];
};

const findById = (id: Id): Promise<[Conversation, Metadata]> =>
  get<Response>(`/api/conversations/${id}`).then(unpack);

export default findById;
