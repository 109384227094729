import type { Listings, GrailedHits } from "storefront/Listings";
import { fromWindow } from "storefront/Config/PublicConfig";

const GRAILED_HITS: GrailedHits = {
  grailed: 0,
  hype: 0,
  basics: 0,
};

const listings = (w: typeof window): Listings => ({
  hits: GRAILED_HITS,
  listings: [],
  categories: fromWindow(w).categories,
  isLoading: false,
  noMoreListings: false,
  initialPage: "",
  // DEPRECATED
  totalHits: 0,
  initialTotalHits: null,
  feedRequester: null,
  listingsNextPage: null,
});

export default listings;
