import { windowExists } from "./safeWindow";

export const hiring = () => {
  if (process.env.NODE_ENV !== "development" && windowExists()) {
    const css1 =
      "font-family: 'Times New Roman', serif; font-size: 1.6rem; line-height: 1.5; letter-spacing: .025rem;";
    const css2 =
      "color: #4a4a4a;font-family: 'Times New Roman', serif; font-size: 1rem; line-height: 1.5; letter-spacing: .025rem;";
    const message =
      "\n%cLooking for a job? We're hiring.\n%cExplore engineering opportunities at https://boards.greenhouse.io/grailed";
    // eslint-disable-next-line no-console
    console.log(message, css1, css2);
  }
};
