import { LightConversation } from "storefront/Conversation/LightConversation";
import { get } from "storefront/GrailedAPI/request";

type Metadata = {
  unreadCount: number;
};

type Response = {
  data: Array<LightConversation>;
  metadata: Metadata;
};

const getLightConversations = (
  page: number,
  context: "selling" | "buying",
  archived: boolean,
): Promise<Response> =>
  get("/api/conversations", {
    light: true,
    page,
    context,
    archived,
  });

export default getLightConversations;
