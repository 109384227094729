import algoliasearch from "algoliasearch";
import invariant from "assert";
import { camelize } from "camelscore";
import { find } from "lodash";
import i18n from "i18next";
import { Id } from "../lib/Id";
import { Algolia$Filters } from ".";
import { StrataCountsType, allStratas, strataCounts } from "./Listings/stratas";
import { filtersToAlgoliaQuery } from "./filtersToAlgoliaQuery";
import { yieldRejectionsUntilSuccess } from "../lib/async_helpers";
// NOTE: The higher you make this number, the worse the performance of the Show/Hide Filters
// animation will be. 60 is very smooth, 100 is passable, 120+ is noticeably janky. Ideally,
// we'd like to have this number be 20-40.
export const DEFAULT_HITS_PER_PAGE = 80;
const PAGE_BETWEEN_SEARCH_AND_BROWSE = Math.ceil(1000 / DEFAULT_HITS_PER_PAGE);
const ATTRIBUTES_FOR_FACETING = [
  "strata",
  "size",
  "category",
  "category_size",
  "category_path",
  "category_path_size",
  "designers.id",
  "location",
  "marketplace",
  "badges",
  "price_i",
  "sold_price",
];
type Cursor = string;
type PageOfResults = {
  hits: Array<Record<string, any>>;
  strataCounts?: StrataCountsType;
  position: number | Cursor;
};
type Rejection = {
  rejection: any;
};
export class AlgoliaPager {
  query: string;

  filters: string;

  // an Algolia filter string, not one of our feed filter objects!
  hitsPerPage: number;

  searchIndex: Record<string, any>;

  browseIndex: Record<string, any>;

  constructor(
    indexName: string | null | undefined,
    query: string,
    filters: string,
    hitsPerPage: number = DEFAULT_HITS_PER_PAGE,
  ) {
    this.query = query;
    this.filters = filters;
    this.hitsPerPage = hitsPerPage;
    const algoliaAppId = window.PUBLIC_CONFIG.algolia.app_id;
    const searchClient = algoliasearch(
      algoliaAppId,
      window.PUBLIC_CONFIG.algolia.public_search_key,
    );
    const browseClient = algoliasearch(
      algoliaAppId,
      window.PUBLIC_CONFIG.algolia.public_browse_key,
    );
    this.searchIndex = searchClient.initIndex(indexName || "");
    this.browseIndex = browseClient.initIndex(indexName || "");
  }

  static fromFilterState(
    query = "",
    filters: Algolia$Filters = {},
    indexName: string | null | undefined,
    page = 0,
    cursor: string | null | undefined = null,
    hitsPerPage: number = DEFAULT_HITS_PER_PAGE,
  ): AsyncIterator<PageOfResults | Rejection> {
    const algoliaFilters = filtersToAlgoliaQuery(filters);
    const startingPosition = cursor || page;
    const client = new this(indexName, query, algoliaFilters, hitsPerPage);
    return client.pages(startingPosition);
  }

  // returns an instance of the actual AlgoliaPager class so that specific pages can be fetched
  static clientFromFilterState(
    query = "",
    filters: Algolia$Filters = {},
    indexName: string | null | undefined,
    hitsPerPage: number = DEFAULT_HITS_PER_PAGE,
  ): AlgoliaPager {
    const algoliaFilters = filtersToAlgoliaQuery(filters);
    const client = new this(indexName, query, algoliaFilters, hitsPerPage);
    return client;
  }

  static async *listingsSoldBy(
    sellerIds: Array<Id>,
  ): AsyncIterator<PageOfResults | Rejection> {
    if (sellerIds.length === 0) {
      return;
    }

    const sellerFilter = sellerIds
      .map((sellerId) => `user.id:'${sellerId}'`)
      .join(" OR ")
      .concat(` AND marketplace:${i18n.t("MARKETPLACE_ID")}`);
    const index = find(window.PUBLIC_CONFIG.algolia.indexes.listings, {
      name: "created_at desc",
    }) || {
      value: "",
    };
    const indexName = index.value;
    const client = new this(indexName, "", sellerFilter);
    // @ts-expect-error ts-migrate(2504) FIXME: Type 'AsyncIterator<PageOfResults | Rejection, any... Remove this comment to see the full error message
    yield* client.pages(0);
  }

  async *pages(
    position: number | string,
  ): AsyncIterator<PageOfResults | Rejection> {
    if (typeof position === "number") {
      if (position < PAGE_BETWEEN_SEARCH_AND_BROWSE) {
        const [{ hits, nbPages, nbHits }, strataCounts] =
          yield* this.searchForPagePlusFetchStrataCounts(position);
        yield {
          position,
          strataCounts,
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: number; strataCounts: StrataCoun... Remove this comment to see the full error message
          nbHits,
          nbPages,
          hits: camelize(hits),
        };

        for (position += 1; position < nbPages; position++) {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'hits' does not exist on type 'unknown'.
          const { hits } = yield* this.searchForPage(position);
          yield {
            position,
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: number; nbHits: any; nbPages: an... Remove this comment to see the full error message
            nbHits,
            nbPages,
            hits: camelize(hits),
          };
        }

        if (nbPages < PAGE_BETWEEN_SEARCH_AND_BROWSE) {
          return; // no need to browse
        }
      }

      invariant(position === PAGE_BETWEEN_SEARCH_AND_BROWSE);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'hits' does not exist on type 'unknown'.
      const { hits, cursor, nbHits, nbPages } = yield* this.browseForPage(
        position,
      );
      yield {
        position,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: number; nbHits: any; nbPages: an... Remove this comment to see the full error message
        nbHits,
        nbPages,
        hits: camelize(hits),
      };
      position = cursor;
    }

    while (position) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'hits' does not exist on type 'unknown'.
      const { hits, cursor, nbHits, nbPages } = yield* this.browseForCursor(
        position,
      );
      position = cursor;
      yield {
        position,
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ position: string | number; nbHits: any; nb... Remove this comment to see the full error message
        nbHits,
        nbPages,
        hits: camelize(hits),
      };
    }
  }

  // fetch a specific page
  getPage = async function* getSpecificPage(
    position: number,
  ): AsyncIterator<PageOfResults | Rejection> {
    const { hits, nbPages, nbHits } = yield* this.searchForPage(position);
    yield {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ nbHits: any; nbPages: any; position: numbe... Remove this comment to see the full error message
      nbHits,
      nbPages,
      position,
      hits: camelize(hits),
    };
  };

  searchForPagePlusFetchStrataCounts = yieldRejectionsUntilSuccess((page) =>
    Promise.all([
      this.searchIndex.search(this.query, { ...this.params(), page }),
      this.fetchStrataCounts(),
    ]),
  );

  searchForPage = yieldRejectionsUntilSuccess((page) =>
    this.searchIndex.search(this.query, { ...this.params(), page }),
  );

  browseForPage = yieldRejectionsUntilSuccess((page) =>
    this.browseIndex.browse(this.query, { ...this.params(), page }),
  );

  browseForCursor = yieldRejectionsUntilSuccess((cursor) =>
    this.browseIndex.browseFrom(cursor),
  );

  params = () => ({
    filters: this.filters,
    hitsPerPage: this.hitsPerPage,
    facets: ATTRIBUTES_FOR_FACETING,
  });

  async fetchStrataCounts(): Promise<StrataCountsType> {
    const sameFiltersButForAllStratas = this.filters.replace(
      /\(strata[^\)]*\)/,
      allStratas,
    );
    // lol
    const resp = await this.searchIndex.search(this.query, {
      filters: sameFiltersButForAllStratas,
      hitsPerPage: 0,
      facets: ATTRIBUTES_FOR_FACETING,
    });
    const { strata } = resp.facets;
    return strataCounts(strata);
  }
}
