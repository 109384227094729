import type { SellerRating } from "storefront/SellerRating";
import { LISTING_SHOW_FEEDBACK_SUBMIT_SUCCESS } from "../constants/action_types";

export function listingFeedbackSubmitSuccess(sellerRating: SellerRating) {
  return {
    type: LISTING_SHOW_FEEDBACK_SUBMIT_SUCCESS,
    payload: {
      sellerRating,
    },
  };
}
