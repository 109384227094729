// When we render the page and we are inside the app (in a webview)
// we need to make a few DOM changes that are different from the web.
import isWebview from "storefront/lib/isWebview";

export default () => {
  if (isWebview()) {
    // remove header dom node
    const headerDOMNode = window.document.getElementById("globalHeader");

    if (headerDOMNode) {
      headerDOMNode.remove();
    }

    // insert webview class into the body element
    const body = window.document.querySelector("body");

    if (body) {
      body.classList.add("webview");
    }

    // Set viewport to disallow zooming/scaling (so it feels more native when embedded in the app)
    const viewport = window.document.querySelector("meta[name=viewport]");
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    viewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
    );
  }
};
