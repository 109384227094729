import type { Id } from "storefront/lib/Id";

export const REPLY_MODAL_CLOSED: "Conversation.ReplyModalClosed" =
  "Conversation.ReplyModalClosed";
export type ReplyModalClosed = {
  type: typeof REPLY_MODAL_CLOSED;
  payload: {
    conversationId: Id | null | undefined;
  };
};
export const replyModalClosed = (conversationId?: Id): ReplyModalClosed => ({
  type: REPLY_MODAL_CLOSED,
  payload: {
    conversationId,
  },
});
