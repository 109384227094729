import { Id } from "storefront/lib/Id";
import { WithholdingInfo } from "storefront/Listing";

export const GRANT_EXTENSION_SUCCESS =
  "Conversation.GrantExtensionSuccess" as const;

export type GrantExtensionSuccess = {
  type: typeof GRANT_EXTENSION_SUCCESS;
  payload: {
    listingId: Id;
    withholdingInfo: WithholdingInfo;
  };
};

export const grantExtensionSuccess = (
  listingId: Id,
  withholdingInfo: WithholdingInfo,
) => ({
  type: GRANT_EXTENSION_SUCCESS,
  payload: {
    listingId,
    withholdingInfo,
  },
});
