import React from "react";
import classnames from "classnames";

const className = (searching: boolean | undefined) =>
  classnames({
    FullPageOverlay: true,
    _searching: searching,
  });

type Props = {
  searching?: boolean;
};

/**
 * @name Overlay
 * @description A full screen overlay
 * @param {Props} props
 * @returns {React$Element<*>}
 */
const NavOverlay = ({ searching }: Props) => (
  <div className={className(searching)} id="FullPageOverlay" />
);

export default NavOverlay;
