import type { Id } from "storefront/lib/Id";

export const OFFER_MODAL_CLOSED: "Conversation.OfferModalClosed" =
  "Conversation.OfferModalClosed";
export type OfferModalClosed = {
  type: typeof OFFER_MODAL_CLOSED;
  payload: {
    conversationId: Id;
  };
};
export const offerModalClosed = (conversationId: Id): OfferModalClosed => ({
  type: OFFER_MODAL_CLOSED,
  payload: {
    conversationId,
  },
});
