import { AlgoliaListing } from "storefront/Listing/AlgoliaListing";
import { get } from "storefront/GrailedAPI/request";
import { Pagination } from "storefront/Pagination";
import { FittingRoomSort } from "storefront/FittingRoom";
import { Id } from "storefront/lib/Id";

type Response = {
  metadata: {
    pagination: Pagination;
  };
  data: Array<AlgoliaListing>;
};

/**
 * @name GrailedAPI.v1.Users.Favorites.getFavoritedListings
 * @param {UserId}
 * @param {FittingRoomSort}
 * @return {Promise<Response>} returns a promise that resolves to the updated listing
 */
const getFavoritedListings = (
  userId: Id,
  sortBy: FittingRoomSort,
  page = 1,
): Promise<Response> =>
  get(`/api/users/${userId}/favorites`, {
    sortBy,
    page,
  });

export default getFavoritedListings;
