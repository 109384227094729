import type { Id } from "storefront/lib/Id";

type Group = {
  createdAt: string;
  id: Id;
  name: string;
  parentId: Id | null;
  updatedAt: string;
};
export type ChildrenGroup = {
  group: Group;
  children?: Array<ChildrenGroup>;
};
export type Groups = {
  group: Group;
  children: Array<ChildrenGroup>;
};
export const emptyGroups: Groups = {
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
  group: {},
  // @ts-expect-error ts-migrate(2741) FIXME: Property 'group' is missing in type '{}' but requi... Remove this comment to see the full error message
  children: [{}],
};
