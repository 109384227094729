// Homepage
export const REMOVE_FROM_FITTING_ROOM = "REMOVE_FROM_FITTING_ROOM";
export const EXPAND_FEED_SETTINGS = "EXPAND_FEED_SETTINGS";
export const CLOSE_FEED_SETTINGS = "CLOSE_FEED_SETTINGS";
export const STICK_FEED_SETTINGS = "STICK_FEED_SETTINGS";
export const UNSTICK_FEED_SETTINGS = "UNSTICK_FEED_SETTINGS";
export const ALGOLIA_LISTINGS_NEXT = "ALGOLIA_LISTINGS_NEXT";
// Internal Tools
export const BAN_IP = "BAN_IP";
export const UNBAN_IP = "UNBAN_IP";
export const INTERNAL_TOOLS_CONTENT_REQUEST = "INTERNAL_TOOLS_CONTENT_REQUEST";
export const INTERNAL_TOOLS_CONTENT_REQUEST_SUCCESS =
  "INTERNAL_TOOLS_CONTENT_REQUEST_SUCCESS";
export const INTERNAL_TOOLS_GROUPS_REQUEST = "INTERNAL_TOOLS_GROUPS_REQUEST";
export const INTERNAL_TOOLS_GROUPS_REQUEST_SUCCESS =
  "INTERNAL_TOOLS_GROUPS_REQUEST_SUCCESS";
export const INTERNAL_TOOLS_FETCH_RELATED_USERS_SUCCESS =
  "INTERNAL_TOOLS_FETCH_RELATED_USERS_SUCCESS";
export const INTERNAL_TOOLS_FETCH_RELATED_USERS_REQUEST =
  "INTERNAL_TOOLS_FETCH_RELATED_USERS_REQUEST";
export const INTERNAL_TOOLS_FETCH_RELATED_USERS_ERROR =
  "INTERNAL_TOOLS_FETCH_RELATED_USERS_ERROR";
// internal/articles
export const FETCH_ARTICLES_REQUEST = "FETCH_ARTICLES_REQUEST";
export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLES_FAILURE = "FETCH_ARTICLES_FAILURE";
export const CREATE_ARTICLE_REQUEST = "CREATE_ARTICLE_REQUEST";
export const CREATE_ARTICLE_SUCCESS = "CREATE_ARTICLE_SUCCESS";
export const CREATE_ARTICLE_FAILURE = "CREATE_ARTICLE_FAILURE";
export const UPDATE_ARTICLE_REQUEST = "UPDATE_ARTICLE_REQUEST";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAILURE = "UPDATE_ARTICLE_FAILURE";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const NEW_ARTICLE = "NEW_ARTICLE";
export const REMOVE_ARTICLE = "REMOVE_ARTICLE";
export const VIEW_ARTICLES_LIST = "VIEW_ARTICLES_LIST";
export const ADD_HERO = "ADD_HERO";
export const REMOVE_HERO = "REMOVE_HERO";
export const REMOVE_LOOK_HERO = "REMOVE_LOOK_HERO";
export const ADD_LISTING = "ADD_LISTING";
export const REMOVE_LISTING = "REMOVE_LISTING";
export const ADD_CAROUSEL_IMAGE = "ADD_CAROUSEL_IMAGE";
export const REMOVE_CAROUSEL_IMAGE = "REMOVE_CAROUSEL_IMAGE";
export const ADD_HERO_TO_LOOK = "ADD_HERO_TO_LOOK";
export const ADD_LOOK = "ADD_LOOK";
export const CHANGE_LOOK_LISTINGS = "CHANGE_LOOK_LISTINGS";
export const CHANGE_ARTICLE_TAGS = "CHANGE_ARTICLE_TAGS";
export const SET_ARTICLE_HOMEPAGE_PREVIEW_REQUEST =
  "SET_ARTICLE_HOMEPAGE_PREVIEW_REQUEST";
export const SET_ARTICLE_HOMEPAGE_PREVIEW_SUCCESS =
  "SET_ARTICLE_HOMEPAGE_PREVIEW_SUCCESS";
export const SET_ARTICLE_HOMEPAGE_PREVIEW_FAILURE =
  "SET_ARTICLE_HOMEPAGE_PREVIEW_FAILURE";
// Internal trust scores
export const FETCH_TRUST_SCORES_REQUEST = "FETCH_TRUST_SCORES_REQUEST";
export const FETCH_TRUST_SCORES_SUCCESS = "FETCH_TRUST_SCORES_SUCCESS";
export const FETCH_TRUST_SCORES_ERROR = "FETCH_TRUST_SCORES_ERROR";
export const CREATE_TRUST_SCORE_REQUEST = "CREATE_TRUST_SCORE_REQUEST";
export const CREATE_TRUST_SCORE_SUCCESS = "CREATE_TRUST_SCORE_SUCCESS";
export const CREATE_TRUST_SCORE_ERROR = "CREATE_TRUST_SCORE_ERROR";
// Internal merchant ids
export const FETCH_MERCHANT_REQUEST = "FETCH_MERCHANT_REQUEST";
export const FETCH_MERCHANT_SUCCESS = "FETCH_MERCHANT_SUCCESS";
export const FETCH_MERCHANT_ERROR = "FETCH_MERCHANT_ERROR";
// Capsules
export const CAPSULES_CMS_EDIT_DELETED = "CapsulesCMS.Edit.DELETED";
export const CAPSULES_CMS_EDIT_UPDATED = "CapsulesCMS.Edit.UPDATED";
export const CAPSULES_CMS_EDIT_PUBLISHED = "CapsulesCMS.Edit.PUBLISHED";
export const CAPSULES_CMS_EDIT_UNPUBLISHED = "CapsulesCMS.Edit.UNPUBLISHED";
// Conversations
export const FETCH_CONVERSATIONS_REQUEST = "FETCH_CONVERSATIONS_REQUEST";
export const FETCH_CONVERSATIONS_SUCCESS = "FETCH_CONVERSATIONS_SUCCESS";
export const FETCH_CONVERSATIONS_ERROR = "FETCH_CONVERSATIONS_ERROR";
export const FETCH_CONVERSATION_REQUEST = "FETCH_CONVERSATION_REQUEST";
export const FETCH_CONVERSATION_SUCCESS = "FETCH_CONVERSATION_SUCCESS";
export const FETCH_CONVERSATION_ERROR = "FETCH_CONVERSATION_ERROR";
export const FOCUS_CONVERSATION = "FOCUS_CONVERSATION";
export const UNFOCUS_CONVERSATION = "UNFOCUS_CONVERSATION";
export const CONVERSATION_EXPAND_ACTIVITY_LOG =
  "CONVERSATION_EXPAND_ACTIVITY_LOG";
export const CONVERSATION_FINISHED_SLIDING_DOWN_ACTIVITY_LOG =
  "CONVERSATION_FINISHED_SLIDING_DOWN_ACTIVITY_LOG";
export const MARK_CONVERSATION_AS_READ = "MARK_CONVERSATION_AS_READ";
export const MARK_CONVERSATION_AS_READ_SUCCESS =
  "MARK_CONVERSATION_AS_READ_SUCCESS";
export const SET_CONVERSATION_CONTEXT = "SET_CONVERSATION_CONTEXT";
export const EXPAND_CONVERSATION_SETTINGS = "EXPAND_CONVERSATION_SETTINGS";
export const HIDE_CONVERSATION_SETTINGS = "HIDE_CONVERSATION_SETTINGS";
export const BLOCK_CONVERSATION_USER_SUCCESS: "BLOCK_CONVERSATION_USER_SUCCESS" =
  "BLOCK_CONVERSATION_USER_SUCCESS";
export const BLOCK_CONVERSATION_USER_ERROR: "BLOCK_CONVERSATION_USER_ERROR" =
  "BLOCK_CONVERSATION_USER_ERROR";
export const UNBLOCK_CONVERSATION_USER_SUCCESS: "UNBLOCK_CONVERSATION_USER_SUCCESS" =
  "UNBLOCK_CONVERSATION_USER_SUCCESS";
export const UNBLOCK_CONVERSATION_USER_ERROR: "UNBLOCK_CONVERSATION_USER_ERROR" =
  "UNBLOCK_CONVERSATION_USER_ERROR";
export const ARCHIVE_CONVERSATION_SUCCESS: "ARCHIVE_CONVERSATION_SUCCESS" =
  "ARCHIVE_CONVERSATION_SUCCESS";
export const UNARCHIVE_CONVERSATION_SUCCESS: "UNARCHIVE_CONVERSATION_SUCCESS" =
  "UNARCHIVE_CONVERSATION_SUCCESS";
export const OPEN_OFFER_MODAL = "OPEN_OFFER_MODAL";
export const OPEN_REPLY_MODAL = "OPEN_REPLY_MODAL";
// Offers
export const ACCEPT_OFFER_REQUEST = "ACCEPT_OFFER";
export const ACCEPT_OFFER_SUCCESS = "ACCEPT_SUCCESS";
export const ACCEPT_OFFER_ERROR = "ACCEPT_ERROR";
// Fitting Room aka Favorites aka My Grails
export const FETCH_FOLLOWED_CAPSULES_SUCCESS =
  "FETCH_FOLLOWED_CAPSULES_SUCCESS";
export const FETCH_FOLLOWED_CAPSULES_ERROR = "FETCH_FOLLOWED_CAPSULES_ERROR";
// Session
export const CHANGE_HEADER_ACTIVE = "CHANGE_HEADER_ACTIVE";
export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const FETCH_INITIAL_USER_SUCCESS = "FETCH_INITIAL_USER_SUCCESS";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
// SellForm
export const SHOW_SELLFORM_DETAILS = "SHOW_SELLFORM_DETAILS";
export const HIDE_SELLFORM_DETAILS = "HIDE_SELLFORM_DETAILS";
// Filtering
export const FETCH_DESIGNERS_ERROR = "FETCH_DESIGNERS_ERROR";
export const FILTERING_DESIGNERS_SEARCH_CHANGED =
  "Filtering.Designers.Search.CHANGED";
export const FILTERING_DESIGNERS_SEARCH_FOCUSED =
  "Filtering.Designers.Search.FOCUSED";
export const FILTERING_DESIGNERS_SEARCH_CLEARED =
  "Filtering.Designers.Search.CLEARED";
export const FILTERING_DESIGNERS_SEARCH_RESPONSE_RECEIVED =
  "Filtering.Designers.Search.RESPONSE_RECEIVED";

// Listing Show Page
export const LISTING_SHOW_FEEDBACK_SUBMIT_SUCCESS =
  "LISTING_SHOW_FEEDBACK_SUBMIT_SUCCESS";
export const LOGGED_IN_BUYER_RESPONSE_RECEIVED =
  "Listings.LoggedInBuyerCTA.responseReceived";
export const LOGGED_IN_BUYER_CTA_MOUNTED = "Listings.LoggedInBuyerCTA.mounted";
// Two Factor Authentication
export const TWO_FACTOR_AUTH_MOUNTED = "SecurityAccountPage.OtpStatus.MOUNTED";
export const TWO_FACTOR_RESPONSE_RECEIVED =
  "SecurityAccountPage.OtpStatus.RECEIVED";
