import type { Filters } from "storefront/Filters";
import { fromWindow } from "storefront/Config/PublicConfig";
import searchQuery from "./searchQuery";
import sort from "./sort";

// DEPRECATED
const filters = (w: typeof window): Filters => ({
  appliedSearchId: null,
  categories: fromWindow(w).categories,
  designerSearchQuery: "",
  filteredDesigners: [],
  manageSavedFiltersModal: {
    isOpen: false,
    editingUUID: null,
  },
  savedSearches: [],
  searchQuery: searchQuery(w),
  sizes: fromWindow(w).sizes,
  sort: sort(w),
});

export default filters;
