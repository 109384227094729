import { camelize } from "camelscore";
import formatListing from "storefront/Listing/formatListing";
import type { ListingShow } from "../types/ListingShow";

const listingShow = (w: typeof window): ListingShow => ({
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'CURRENT_LISTING' does not exist on type ... Remove this comment to see the full error message
  currentListing: w.CURRENT_LISTING
    ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'CURRENT_LISTING' does not exist on type ... Remove this comment to see the full error message
      formatListing(camelize(w.CURRENT_LISTING))
    : null,
  recommendedListingsRequest: false,
});

export default listingShow;
