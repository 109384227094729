import { Id } from "storefront/lib/Id";
import { FittingRoomSort, FittingRoomPager } from "storefront/FittingRoom";
import { get } from "storefront/GrailedAPI/request";
import { yieldRejectionsUntilSuccess } from "storefront/lib/async_helpers";
import getFavoritedListings from "storefront/GrailedAPI/v1/Users/Favorites/getFavoritedListings";

const fetchNextPage = (url: string): Promise<any> => get(url);

async function* fittingRoomPager(
  id: Id,
  sortBy: FittingRoomSort,
): FittingRoomPager {
  let { data, metadata } = yield* yieldRejectionsUntilSuccess(
    getFavoritedListings,
  )(id, sortBy);

  if (!data.length) {
    return;
  }

  yield {
    hits: data,
    tag: "success",
  };

  let { nextPage } = metadata.pagination;

  while (nextPage) {
    const newData = yield* yieldRejectionsUntilSuccess(fetchNextPage)(nextPage);
    data = newData.data;
    metadata = newData.metadata;

    if (!data.length) {
      return;
    }

    yield {
      hits: data,
      tag: "success",
    };

    /* eslint-disable-next-line prefer-destructuring */
    nextPage = metadata.pagination.nextPage;
  }
}

export default fittingRoomPager;
