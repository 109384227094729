/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
import FlashManager from "storefront/lib/flash/FlashManager";

export default function showFlash(): void {
  const alert = (window as any).FLASH_ALERT_MESSAGE;

  if (alert) {
    FlashManager.getInstance().alert(alert);
  }

  const notice = (window as any).FLASH_NOTICE_MESSAGE;

  if (notice) {
    FlashManager.getInstance().notice(notice);
  }
}
