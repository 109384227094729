export type StrataCountsType = {
  grailed: number;
  hype: number;
  basic: number;
  core: number;
  grails: number;
};
export const allStratas =
  "(strata: 'grailed' OR strata:'basic' OR strata:'hype' OR strata:'sartorial')";
export function strataCounts(strata: {
  grailed?: number;
  hype?: number;
  basic?: number;
  core?: number;
  grails?: number;
  sartorial?: number;
}): StrataCountsType {
  return {
    grailed: (strata && strata.grailed) || 0,
    hype: (strata && strata.hype) || 0,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ grailed: number; hype: number; sartorial: ... Remove this comment to see the full error message
    sartorial: (strata && strata.sartorial) || 0,
    basic: (strata && strata.basic) || 0,
    core: (strata && strata.core) || 0,
    grails: (strata && strata.grails) || 0,
  };
}
