import type { Reducer } from "redux";
import { compose, set, update } from "lodash/fp";
import type { Action } from "storefront/Action";
import {
  FOLLOW_CLICKED,
  UNFOLLOW_ERRORED,
  UNFOLLOW_CLICKED,
  FOLLOW_ERRORED,
} from "storefront/components/ListingPage/Likes/Action/Constants";
import { CREATE_SHIPMENT_SUCCESS } from "storefront/ReduxActions/Shipment/CreateShipment";
import { EDIT_SHIPMENT_SUCCESS } from "storefront/ReduxActions/Shipment/EditShipment";
import type { ListingShow } from "../types/ListingShow";
import {
  LISTING_SHOW_FEEDBACK_SUBMIT_SUCCESS,
  LOGGED_IN_BUYER_RESPONSE_RECEIVED,
} from "../constants/action_types";

const initialState: ListingShow = {
  currentListing: null,
  recommendedListingsRequest: false,
};

const listingShow: Reducer<ListingShow, Action> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case LISTING_SHOW_FEEDBACK_SUBMIT_SUCCESS: {
      if (!state.currentListing) return state;
      return {
        ...state,
        currentListing: {
          ...state.currentListing,
          sellerRating: action.payload.sellerRating,
        },
      };
    }

    case FOLLOW_CLICKED:
    case UNFOLLOW_ERRORED:
      return compose([
        set("currentListing.followed", true),
        update("currentListing.followerCount", (f) => f + 1),
      ])(state);

    case UNFOLLOW_CLICKED:
    case FOLLOW_ERRORED:
      return compose([
        set("currentListing.followed", false),
        update("currentListing.followerCount", (f) => f - 1),
      ])(state);

    case LOGGED_IN_BUYER_RESPONSE_RECEIVED:
      return compose([
        set("lastOffer.requested", true),
        set("lastOffer.offer", action.payload),
      ])(state);

    case CREATE_SHIPMENT_SUCCESS:
    case EDIT_SHIPMENT_SUCCESS: {
      if (!state.currentListing) return state;
      return {
        ...state,
        currentListing: {
          ...state.currentListing,
          shipments: [action.payload.shipment],
        },
      };
    }

    default:
      return state;
  }
};

export default listingShow;
